/* @flow */
import React, { Component } from 'react';
import moment from 'moment';
import { Paper, Grid } from '@mui/material';
import customStyles from './CameraRecordings.module.scss';
import { getDashcamToken, getDashcamOrgId } from '../../../util/dashcam_util';
import {
    getAppUserSettings, getTimeFormatAsperUserSettting,
} from '../../../helper-classes/utility-functions';
import constants from '../../../components/Common/constants.common';

export type Props = {
    imei: string,
};

class CameraRecordings extends Component<Props> {
    playerRef: Object;
    constructor(props: Props) {
        super(props);
        this.playerRef = React.createRef();
    }

    render() {
        const userTimeZoneValue = moment.tz(getAppUserSettings('userTz')).format('Z');
        const is12HourFormat = getTimeFormatAsperUserSettting() === '12h';

        return (
            <Paper
                id="dashcam-camera-recording-container"
                className={customStyles.recording_container}
            >
                <Grid container justifyContent="space-around" alignItems="stretch">
                    <Grid item xs={7}>
                        <lytx-recording-timeline
                            ref={this.playerRef}
                            auth-token={getDashcamToken()}
                            imei={this.props.imei}
                            organization-id={getDashcamOrgId()}
                            primary-color={constants.primaryColor}
                            time-zone-value={userTimeZoneValue}
                            events={false}
                            trips={false}
                            driver-name={false}
                            font-family="Roboto"
                            is-12-hour={is12HourFormat}
                        />
                    </Grid>
                    <Grid item xs={5} />
                </Grid>
            </Paper>
        );
    }
}

export default CameraRecordings;
