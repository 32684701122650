/* @flow */
import React from 'react';
import moment from 'moment';
import {
    Radio, Button, Grid, Chip, Paper, Typography, TextField,
    Table, Select, TableBody, TableCell, MenuItem, TableRow,
    Checkbox, InputLabel, Divider, IconButton, SvgIcon, Popover, Toolbar, DialogActions,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { ContentCopy } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { CompactPicker } from 'react-color';
import InputAdornment from '@mui/material/InputAdornment';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import { LocalizationProvider, MobileDatePicker, PickersDay } from '@mui/x-date-pickers';
import MomentUtils from '@date-io/moment';
import extStyles from './AddEditAsset.module.scss';
import * as c from './constants.addEditAsset';
import GetSvgIcon from '../../util/svgImage_util';
import {
    convertBackToMeters,
    getAttributeType,
    getAssetTypes,
    getAssetTypeCodeProperty,
    addAsset,
    editAsset,
    getDistanceStringLong,
    getDistanceString,
    getCorrectDistance,
    getLocalFleetAsset,
    getOdometerAdjustmentHistory,
    getEngineTimeAdjustmentHistory,
    hasFeature,
    getParamValue,
    copyTextToClipboard,
    getDeviceSerial, getPermissionValue, getUsername, getInstallationRecord,
    formatInstallationTest, formatInstallationAddress, getInstallationType,
    convertDateInGivenFormat, convertUnitValueAsPerUserSetting, getSpeedUnitString,
    reverseUnitValAsPerUserSetting,
} from '../../helper-classes/utility-functions';
import SaveErrorDialog from '../Maintenance/SaveErrorDialog';
import AddEditHeader from './AddEditHeader';
import { getImage } from '../../util/trip_util';
import AsyncSelectWrapper from '../../components/SharedComponents/AsyncSelectWrapper';
import AppLoader from '../../components/AppLoader';
import MaintenanceCard from './MaintenanceCard';
import analytics from '../../analytics/index';
import Dialog from '../../components/SharedComponents/Dialog/Dialog';
import { createAssetDashcam, deleteAssetDashcam, getAssetDashcamByAssetId, getLMDevices } from './../DashCam/helper-classes/dashcamServices';
import config from '../../constants/Config';

export type Props = {
    classes : Object,
    match: any,
    history: {
        push: Function,
        goBack: Function,
    },
};

export type State = {
    chipData: any,
    safetyVisible: boolean,
    ELDVisible: boolean,
    installationVisible: boolean,
    fuelCardVisible: boolean,
    isFuliDevice: boolean,
    maintenanceVisible: boolean,
    deleteDialogOpen: boolean,
    errorDialogOpen: boolean,
    successDialogOpen: boolean,
    assetTypes: any,
    attributeTypes: any,
    fuelTypes: any,
    selectedFilters: Array<{}>,
    fuelCardAccounts: Array<{}>,
    loading: boolean,
    isColorDisable: boolean,
    milesTotalHelpOpen: boolean,
    hoursTotalHelpOpen: boolean,
    accountId: string,
    accountLabel: string,
    active: boolean,
    attributeFuelType: string,
    attributeCapacity: string,
    attributeColor: string,
    attributeIcon: string,
    assetTypeIdProperty: string,
    assetTypeId: string,
    assetIcon: string,
    assetTypeLabel: string,
    attributesFlattened: string,
    attributesJSON: string,
    createdBy: string,
    dateCreated: string,
    description: string,
    deviceDescription: string,
    deviceId: number,
    deviceIdProperty: number,
    deviceInput1: string,
    deviceInput2: string,
    deviceInput3: string,
    deviceInput4: string,
    deviceInput5: string,
    deviceInput6: string,
    deviceInput7: string,
    deviceInput8: string,
    deviceLabel: string,
    deviceName: string,
    deviceProductType: any,
    deviceSerialNumber: string,
    camCoachNumber: string,
    deviceSku: string,
    deviceTypeCode: string,
    deviceModelCode: string,
    deviceUniqueId: string,
    eldRequired: boolean,
    externalId: string,
    fuelCardAccountId: string,
    fuelCardId: string,
    fuelCardNumber: string,
    groupId: number,
    groupName: string,
    highSpeed: string,
    highSpeedChecked: boolean,
    highRPM: string,
    highRPMChecked: boolean,
    hardBraking: boolean,
    hardAcceleration: boolean,
    id: string,
    input1Type: string,
    input1HighName: string,
    input1HighStateName: string,
    input1LowName: string,
    input1LowStateName: string,
    input2Type: string,
    input2HighName: string,
    input2HighStateName: string,
    input2LowName: string,
    input2LowStateName: string,
    input3Type: string,
    input3HighName: string,
    input3HighStateName: string,
    input3LowName: string,
    input3LowStateName: string,
    input4Type: string,
    input4HighName: string,
    input4HighStateName: string,
    input4LowName: string,
    input4LowStateName: string,
    hasCustomInputChanged: boolean,
    internalId: string,
    lastUpdated: string,
    make: string,
    matchId: string,
    model: string,
    assetName: string,
    dashcamIdentifier: string,
    apiDashcamIdentifier: string,
    name: string,
    operatorFirstName: string,
    operatorId: string,
    operatorLastName: string,
    intialOdometer: number,
    odometerAdjustmentHistory: any,
    odometerAdjustmentDate: any,
    odometerAdjustmentVal: string,
    odometerAdjustment: number,
    gpsTrackedDistance: number,
    stateTotalDistance: number,
    assetDistanceSum: number,
    initialEngineTime: string,
    intialHubometer: string,
    engineTimeAdjustmentHistory: any,
    engineHourAdjustmentDate: any,
    engineHourAdjustmentVal: string,
    engineTimeAdjustment: number,
    stateTrackedEngineTime: any,
    engineTimeSum: number,
    stateTotalEngineTime: number,
    selectedTag: string,
    tags: string,
    updatedBy: string,
    version: number,
    vin: string,
    wb1Checked: boolean,
    wb2Checked: boolean,
    wb3Checked: boolean,
    wb4Checked: boolean,
    year: string,
    globalId: string,
    hardBrakingChecked: number,
    hardAccelerationChecked: number,
    errorMessage: string,
    errorTitle: string,
    tabValue: string,
    validationDialogBox: {
        isError: boolean,
        msg: string,
        header: string,
    },
    selectedAsset: any,
    editMode: boolean,
    contentCopyTitle: string,
    showCopyContent: boolean,
    showCamCoachCopyContent: boolean,
    attributeNotes: string,
    mixpanelOriginalState: any,
    installationSection: any,
    isFl360DeviceLabel: boolean,
    engineHourDatePickerOpen: boolean,
    engineHourPickedDate: Date,
    odometerDatePickerOpen: boolean,
    odometerPickedDate: Date,
    sendFL360Commands: boolean,
    selectedDashcam: any,
    trueOdometerSourceOn: boolean,
    trueEngineTimeSourceOn: boolean,
}

const defaultDataArrayParam = 'content';
const defaultDataCountParam = 'count';
const defaultDataTotalParam = 'total';
const defaultDatumLabelParam = 'name';
const defaultDatumValueParam = 'id';
const defaultPerPage = 25;
const customHiOnloOff = 'custom.hiOnloOff';
const customHiOffloOn = 'custom.hiOffloOn';

type Response = any;
type Ref = {current: any | HTMLDivElement};
const styles = () => (c.styles);

class AddEditAsset extends React.Component<Props, State> {
    infoRef: Ref;
    button: Array<Object>;
    updatesRef: Ref;
    installationRef: Ref;
    associationsRef: Ref;
    inputsRef: Ref;
    fuelCardRef: Ref;
    complianceRef: Ref;
    safetyRef: Ref;
    maintenance: Ref;
    localStorage: Storage;
    anchorEl: Object;
    distanceString: Object;
    getLocalFleetAssetCalled: boolean;
    isDelete: boolean;
    id: string;
    editUrl: string;
    initialDataObjForRetrieval: Object;
    allowSelectBoxReassignment: boolean;
    freeTextSelectBoxList: Array<string>;
    trackingDistanceFields: Object;
    trackingEngineHoursFields: Object;

    constructor(props: Props) {
        super(props);
        this.infoRef = React.createRef();
        this.updatesRef = React.createRef();
        this.installationRef = React.createRef();
        this.associationsRef = React.createRef();
        this.inputsRef = React.createRef();
        this.fuelCardRef = React.createRef();
        this.complianceRef = React.createRef();
        this.safetyRef = React.createRef();
        this.maintenance = React.createRef();

        this.state = this.initState();
        this.setLocalVars();
        this.distanceString = {
            long: getDistanceStringLong(),
            short: getDistanceString(),
        };
        this.initialDataObjForRetrieval = {};
        this.allowSelectBoxReassignment = false;
        this.freeTextSelectBoxList = ['fuelCardAccountId', 'fuelCardId', 'selectedTag', 'input1HighName', 'input2HighName', 'input3HighName', 'input4HighName'];
        this.trackingDistanceFields = {};
        this.trackingEngineHoursFields = {};
    }

    UNSAFE_componentWillMount() {
        document.addEventListener('keydown', (evt: any) => {
            const e = evt || window.event;
            if (e.keyCode === 27) {
                this.handleDialogClose('success');
            }
        });
    }

    componentDidMount() {
        if (this.state.tabValue === 'info') {
            setTimeout(() => { this.setLocalVars(); }, 3000);
        }
        this.loadInitPage();
    }

    UNSAFE_componentWillReceiveProps() {
        this.isVisibleFuelCardOrELD();
    }

    componentDidUpdate() {
        if (this.props.match.params.id && this.props.match.params.id !== this.id) {
            this.loadInitPage();
        }
    }

    initState = () => {
        this.id = getParamValue(this.props, 'id');
        this.editUrl = '/assets/edit/:id';
        let editMode = false;
        if (this.id && this.editUrl === this.props.match.path) editMode = true;

        return {
            ...c.initialState,
            assetTypes: (this.state) ? this.state.assetTypes || [] : [],
            attributeTypes: (this.state) ? this.state.attributeTypes || [] : [],
            selectedAsset: [],
            chipData: [],
            odometerAdjustmentHistory: [],
            engineTimeAdjustmentHistory: [],
            fuelCardAccounts: [],
            selectedFilters: [],
            validationDialogBox: {
                isError: false,
                msg: '',
                header: '',
            },
            dashcamIdentifier: '',
            apiDashcamIdentifier: '',
            editMode,
            maintenanceVisible: editMode && getPermissionValue('Maintenance') !== 'None',
            tabValue: 'info',
            contentCopyTitle: 'Copy',
            showCopyContent: false,
            showCamCoachCopyContent: false,
            mixpanelOriginalState: [],
            isFuliDevice: false,
            engineHourDatePickerOpen: false,
            engineHourPickedDate: null,
            odometerDatePickerOpen: false,
            odometerPickedDate: null,
            sendFL360Commands: config.get('SEND_FL360_COMMANDS') === 'true' || config.get('SEND_FL360_COMMANDS') === true,
            selectedDashcam: null,
            hasCustomInputChanged: false,
        };
    }

    loadInitPage = () => {
        this.setState({ ...this.initState() });
        this.id = getParamValue(this.props, 'id');

        if (this.id) {
            getInstallationRecord(this.id).then((resp) => {
                if (resp && resp.content && resp.content[0]) {
                    this.setState({ installationVisible: true });
                    const content = resp.content[0];
                    const { installationSection } = this.state;
                    if (content.installationTests) {
                        Object.keys(content.installationTests).forEach((key) => {
                            if (content.installationTests[key] &&
                                content.installationTests[key].resource) {
                                const installationResource =
                                    content.installationTests[key].resource;
                                if (installationResource.type === 'GPS_LOCK') {
                                    installationSection.GPSTest = installationResource.currentState ? formatInstallationTest(installationResource.currentState) : '--';
                                    installationSection.GPSLocation =
                                        installationResource.testData
                                        && Object.keys(installationResource.testData).length !== 0 ? formatInstallationAddress(installationResource.testData) : '--';
                                } else if (installationResource.type === 'CELLULAR') {
                                    installationSection.cellularTest = installationResource.currentState ? formatInstallationTest(installationResource.currentState) : '--';
                                } else if (installationResource.type === 'IGNITION_BASIC') {
                                    installationSection.ignitionTest = installationResource.currentState ? formatInstallationTest(installationResource.currentState) : '--';
                                } else if (installationResource.type === 'ONE_WIRE_ID_CHECK') {
                                    installationSection.driverIDTest = installationResource.currentState ? formatInstallationTest(installationResource.currentState) : '--';
                                } else if (installationResource.type === 'STARTER_BASIC') {
                                    installationSection.starterTest = installationResource.currentState ? formatInstallationTest(installationResource.currentState) : '--';
                                } else if (installationResource.type === 'FULI_CELLULAR') {
                                    this.setState({ isFuliDevice: true });
                                    try {
                                        installationSection.batteryPercentage =
                                            installationResource.testData &&
                                            installationResource.testData.internalBatteryPercentage
                                            && !Number.isNaN(parseFloat(installationResource
                                                .testData.internalBatteryPercentage)) ? `${(installationResource.testData
                                                    .internalBatteryPercentage * 10) / 10}%` : '--';
                                    } catch (e) {
                                        // eslint-disable-next-line no-console
                                        console.log(e);
                                    }
                                    installationSection.cellularTest = installationResource.currentState ? formatInstallationTest(installationResource.currentState) : '--';
                                } else if (installationResource.type === 'DEVICE_BATTERY_VOLTAGE') {
                                    installationSection.batteryTest = installationResource.currentState ? formatInstallationTest(installationResource.currentState) : '--';
                                }
                            }
                        });
                    }
                    installationSection.date = content.dateCreated ? convertDateInGivenFormat(content.dateCreated, 'MM/DD/YYYY') : '--';
                    installationSection.deviceSerial = content.deviceSerialNumber ? content.deviceSerialNumber : '--';
                    installationSection.installationType = content.attributes &&
                    content.attributes.INSTALLATION_TYPE ? getInstallationType(content.attributes.INSTALLATION_TYPE, content.attributes.HARNESS_TYPE) : '--';
                    installationSection.installedLocation = content.attributes && content.attributes.DEVICE_INSTALLATION_LOCATION ? content.attributes.DEVICE_INSTALLATION_LOCATION : '--';
                    installationSection.installedDevices = content.attributes && content.attributes.OTHER_INSTALLED_DEVICES ? content.attributes.OTHER_INSTALLED_DEVICES : '--';
                    installationSection.installerNotes = content.notes ? content.notes : '';
                    const fuliInstallationSection = {
                        date: installationSection.date ? installationSection.date : '--',
                        GPSLocation: installationSection.GPSLocation ? installationSection.GPSLocation : '--',
                        deviceSerial: installationSection.deviceSerial ? installationSection.deviceSerial : '--',
                        cellularTest: installationSection.cellularTest ? installationSection.cellularTest : '--',
                        GPSTest: installationSection.GPSTest ? installationSection.GPSTest : '--',
                        batteryTest: installationSection.batteryTest ? installationSection.batteryTest : '--',
                        batteryPercentage: installationSection.batteryPercentage ? installationSection.batteryPercentage : '--',
                        installationType: installationSection.installationType ? installationSection.installationType : '--',
                        installedLocation: installationSection.installedLocation ? installationSection.installedLocation : '--',
                        installerNotes: installationSection.installerNotes ? installationSection.installerNotes : '',
                    };
                    const FL360InstallationSection = {
                        date: installationSection.date ? installationSection.date : '--',
                        GPSLocation: installationSection.GPSLocation ? installationSection.GPSLocation : '--',
                        deviceSerial: installationSection.deviceSerial ? installationSection.deviceSerial : '--',
                        cellularTest: installationSection.cellularTest ? installationSection.cellularTest : '--',
                        GPSTest: installationSection.GPSTest ? installationSection.GPSTest : '--',
                        ignitionTest: installationSection.ignitionTest ? installationSection.ignitionTest : '--',
                        driverIDTest: installationSection.driverIDTest ? installationSection.driverIDTest : '--',
                        starterTest: installationSection.starterTest ? installationSection.starterTest : '--',
                        installationType: installationSection.installationType ? installationSection.installationType : '--',
                        installedLocation: installationSection.installedLocation ? installationSection.installedLocation : '--',
                        installedDevices: installationSection.installedDevices ? installationSection.installedDevices : '--',
                        installerNotes: installationSection.installerNotes ? installationSection.installerNotes : '',
                    };
                    if (this.state.isFuliDevice) {
                        this.setState({ installationSection: fuliInstallationSection });
                    } else {
                        this.setState({ installationSection: FL360InstallationSection });
                    }
                }
                // ignore installation API errors
                // eslint-disable-next-line no-unused-vars
            }).catch((error) => {});
            getLocalFleetAsset(this.id).then((response) => {
                if (response && response.data && response.data.globalId) {
                    this.setState({
                        selectedAsset: response.data,
                    }, () => {
                        this.loadPageData();
                        this.getLocalFleetAssetCalled = false;
                        this.loadAssetData();
                    });
                } else {
                    this.setState({
                        validationDialogBox: {
                            isError: true,
                            msg: 'Selected asset not found',
                            header: 'Edit Asset',
                        },
                    });
                }
            });
            if (this.showDashcam() || this.showLMDashcam()) {
                getAssetDashcamByAssetId(this.id).then((res) => {
                    const dashcamObj = {
                        dashcamIdentifier: res.dashcamIdentifier || '',
                        apiDashcamIdentifier: res.dashcamIdentifier || '',
                    };
                    if (this.showLMDashcam() && res.dashcamIdentifier) {
                        dashcamObj.selectedDashcam = {
                            label: res.dashcamIdentifier || '',
                            value: res.dashcamIdentifier || '',
                        }
                        this.setState({
                            showCamCoachCopyContent: true,
                        })
                    }
                    this.setState(dashcamObj);
                }).catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
            }
        } else {
            this.loadPageData();
        }
    }

    loadPageData = () => {
        const { assetTypes, attributeTypes } = this.state;
        if (!assetTypes || (assetTypes && assetTypes.length <= 0)) {
            getAssetTypes().then((res) => {
                if (res.error) {
                    this.handleDialogOpen('error', res.error, res.error);
                } else {
                    const assetTypesData = [];
                    res.data.forEach((assetType) => {
                        assetTypesData.push({
                            value: assetType.id,
                            label: assetType.name,
                            code: assetType.code,
                        });
                    });
                    this.setState({ assetTypes: assetTypesData, loading: false });
                }
            });
        }
        if (!attributeTypes || (attributeTypes && attributeTypes.length <= 0)) {
            getAttributeType().then((res) => {
                if (res.error) {
                    this.handleDialogOpen('error', res.error, res.error);
                } else {
                    const attributeTypesData = [];
                    res.data.forEach((attributeType) => {
                        attributeTypesData.push({
                            value: attributeType.id,
                            name: attributeType.name,
                        });
                    });
                    this.setState({ attributeTypes: attributeTypesData, loading: false });
                }
            });
        }
        this.isVisibleFuelCardOrELD();
    }

    loadAssetData = () => {
        const { selectedAsset } = this.state;
        if (selectedAsset) {
            const {
                globalId, id, groupId, deviceSerialNumber,
            } = selectedAsset;

            this.initialDataObjForRetrieval.trackingDeviceSerial = deviceSerialNumber;
            let showCopyContent = false;
            if (deviceSerialNumber) showCopyContent = true;
            this.setState({
                groupId,
                deviceSerialNumber,
                odometerAdjustment: selectedAsset.odometerAdjustment,
                showCopyContent,
                active: selectedAsset.active,
                groupName: selectedAsset.groupName,
                assetName: selectedAsset.name || '',
                vin: selectedAsset.vin || '',
                year: selectedAsset.year || '',
                make: selectedAsset.make || '',
                model: selectedAsset.model || '',
                assetTypeLabel: selectedAsset.assetTypeLabel || '',
                attributeIcon: selectedAsset.attributeIcon || '',
            });
            if (globalId || id) {
                if (!this.getLocalFleetAssetCalled) {
                    this.setState({ loading: true });
                    this.getLocalFleetAssetCalled = true;
                    getLocalFleetAsset(globalId || id).then((res) => {
                        const { data } = res;
                        if (!data || !data.globalId || !data.id) {
                            this.props.history.goBack();
                        }

                        if (data && data.deviceTypeCode && data.deviceTypeCode.toLowerCase().includes('xirgo')) {
                            this.setState({ safetyVisible: true });
                        }

                        let nS = Object.assign({}, this.state);
                        if (data.attributesJSON) {
                            JSON.parse(data.attributesJSON).forEach((attr) => {
                                if (attr.attributeTypeName === 'fuelType') {
                                    nS.attributeFuelType = attr.value;
                                } else if (attr.attributeTypeName === 'fuelTankCapacity') {
                                    nS.attributeCapacity = (parseInt(attr.value, 10) || 0)
                                        .toString();
                                } else if (attr.attributeTypeName === 'localfleet.assetLabelColor') {
                                    nS.attributeColor = attr.value;
                                } else if (attr.attributeTypeName === 'localfleet.assetCustomIcon') {
                                    const [,, temp] = attr.value.split('.');
                                    nS.attributeIcon = temp;
                                } else if (attr.attributeTypeName === 'maxSpeedThreshold') {
                                    nS.highSpeed = this.fetchSafetyProfileTextValue(convertUnitValueAsPerUserSetting(attr.value, 'round')
                                        .toString(), '0');
                                    nS.highSpeedChecked = attr.value > 0;
                                } else if (attr.attributeTypeName === 'rpmThreshold') {
                                    nS.highRPM = this.fetchSafetyProfileTextValue(attr.value, '0');
                                    nS.highRPMChecked = attr.value > 0;
                                } else if (attr.attributeTypeName === 'decelThreshold') {
                                    nS.hardBraking = attr.value;
                                    nS.hardBrakingChecked = attr.value > 0 ? 1 : 0;
                                } else if (attr.attributeTypeName === 'accelThreshold') {
                                    nS.hardAcceleration = attr.value;
                                    nS.hardAccelerationChecked = attr.value > 0 ? 1 : 0;
                                } else if (attr.attributeTypeName === 'speedViolationBuzzer') {
                                    nS.wb1Checked = attr.value === 'true';
                                } else if (attr.attributeTypeName === 'rpmViolationBuzzer') {
                                    nS.wb2Checked = attr.value === 'true';
                                } else if (attr.attributeTypeName === 'accelViolationBuzzer') {
                                    nS.wb4Checked = attr.value === 'true';
                                } else if (attr.attributeTypeName === 'decelViolationBuzzer') {
                                    nS.wb3Checked = attr.value === 'true';
                                } else if (attr.attributeTypeName === 'localfleet.assetNote') {
                                    nS.attributeNotes = attr.value;
                                }
                            });
                        }

                        if (data.tags) {
                            nS.chipData = [];
                            data.tags.split(',').forEach((tag, key) => {
                                const chip = {
                                    key,
                                    label: tag.split(':')[1],
                                };
                                nS.chipData.push(chip);
                            });
                        }
                        const temp = {
                            input1Type: '',
                            input2Type: '',
                            input3Type: '',
                            input4Type: '',
                        };
                        Object.keys(data).forEach((i) => {
                            if (Object.keys(nS).indexOf(i) > -1) {
                                if (['deviceInput1', 'deviceInput2', 'deviceInput3', 'deviceInput4'].indexOf(i) > -1) {
                                    // eslint-disable-next-line no-plusplus
                                    for (let j = 1; j < 5; j++) {
                                        if (i === `deviceInput${j}` && c.systemInputType.some((r) => r.code === data[`deviceInput${j}`])) {
                                            const matchingSystemInput = c.systemInputType.find((r) => r.code === data[`deviceInput${j}`]);
                                            temp[`input${j}Type`] = 'system';
                                            temp[`input${j}HighName`] = matchingSystemInput.highName;
                                            temp[`input${j}HighStateName`] = matchingSystemInput.highStateName;
                                            temp[`input${j}LowStateName`] = matchingSystemInput.lowStateName;
                                        }
                                    }
                                }
                                if (temp[i] == null || temp[i] === '') {
                                    temp[i] = data[i];
                                }
                            }
                            if (['make', 'model', 'vin', 'year'].indexOf(i) > -1) { temp[i] = data[i] ? data[i] : ''; }
                            if (i === 'intialHubometer') {
                                temp[i] = getCorrectDistance(data[i] / 1000, 'km').toFixed(1);
                                temp.intialOdometer = temp[i].toString();
                            }
                        });

                        let dynamicParams = {};
                        if (hasFeature('fleetWexFuelCard')) {
                            dynamicParams = {
                                fuelCardAccountId: data.wexFuelCardAccountId,
                                fuelCardId: data.wexFuelCardNumber,
                                fuelCardNumber: data.wexFuelCardNumber,
                            };
                        } else if (hasFeature('fleetFleetCorFuelCard')) {
                            dynamicParams = {
                                fuelCardAccountId: data.fleetCorAccountCode,
                                fuelCardId: data.fleetCorVehicleId,
                                fuelCardNumber: data.fleetCorVehicleId,
                            };
                        }
                        nS = {
                            ...nS,
                            ...temp,
                            ...dynamicParams,
                            globalId: globalId || id,
                            deviceIdProperty: data.deviceId,
                            gpsTrackedDistance: data.assetDistance,
                            initialEngineTime: (data.initialEngineTime ?
                                data.initialEngineTime / (60 * 60 * 1000) : 0).toFixed(1),
                            assetName: data.name ? data.name : '',
                            name: `${data.operatorFirstName} ${data.operatorLastName}`,
                            stateTotalEngineTime: +((data.stateTotalEngineTime || 0) / 3600000)
                                .toFixed(1),
                            engineTimeSum: +((data.engineTimeSum || 0) / 3600000).toFixed(1),
                            engineTimeAdjustment: +((data.engineTimeAdjustment || 0) / 3600000)
                                .toFixed(1),
                            stateTrackedEngineTime: data.engineTime ?
                                (data.engineTime / (60 * 60 * 1000)).toFixed(1) : 0,
                            updatedBy: getUsername() || '',
                            loading: false,
                            trueOdometerSourceOn: data.trueOdometerSourceOn,
                            trueEngineTimeSourceOn: data.trueEngineTimeSourceOn,
                        };
                        c.fuelCardInput.id = nS.fuelCardAccountId;
                        if (nS.deviceModelCode === c.modelCode.fl360) {
                            nS.isFl360DeviceLabel = true;
                        }
                        this.setState(nS);
                        this.initialDataObjForRetrieval.intialOdometer = nS.intialOdometer;
                        this.initialDataObjForRetrieval.initialEngineTime = nS.initialEngineTime;
                        getOdometerAdjustmentHistory(data.id).then(OAHRes => this.odoAndEngTimeHistory('odometerAdjustmentHistory', OAHRes));

                        getEngineTimeAdjustmentHistory(data.id).then(ETAHRes => this.odoAndEngTimeHistory('engineTimeAdjustmentHistory', ETAHRes));
                    });
                }
            }
        }
        const params = {
            // odometerAdjustment: selectedAsset.odometerAdjustment,
            // active: selectedAsset.active,
            attributesJSON: selectedAsset.attributesJSON,
            assetName: selectedAsset.name,
            assetTypeLabel: selectedAsset.assetTypeLabel,
            deviceSerialNumber: selectedAsset.deviceSerialNumber,
            groupName: selectedAsset.groupName,
            vin: selectedAsset.vin || '',
            year: selectedAsset.year || '',
            make: selectedAsset.make || '',
            model: selectedAsset.model || '',
        };
        this.setState({ mixpanelOriginalState: params });
    }

    findAttributeIdByName = (attributeName) => {
        let attributeId = '';
        const { attributeTypes } = this.state;
        if (attributeTypes.length > 0) {
            attributeTypes.find((r) => {
                if (r.name === attributeName) {
                    attributeId = r.value;
                }
                return attributeId;
            });
        }
        return attributeId;
    }

    odoAndEngTimeHistory = (type, res) => {
        const { data } = res;
        if (data && data[type]) {
            const historyData = [];
            JSON.parse(data[type]).forEach((history) => {
                if (type === 'odometerAdjustmentHistory') {
                    historyData.push(`${history.odometerAdjustmentDate} - ${getCorrectDistance(history.odometerAdjustment / 1000, 'km').toFixed(1)}`);
                } else {
                    historyData.push(`${history.engineTimeAdjustmentDate} - ${(history.engineTimeAdjustment / (60 * 60 * 1000)).toFixed(1)}`);
                }
            });
            this.setState({ [type]: historyData });
        }

        if (type === 'engineTimeAdjustmentHistory') {
            this.getLocalFleetAssetCalled = false;
        }
    }

    isVisibleFuelCardOrELD = () => {
        if (!this.state.fuelCardVisible && (
            hasFeature('fleetFuelCard') ||
            hasFeature('fleetWexFuelCard') ||
            hasFeature('fleetFleetCorFuelCard'))
        ) {
            this.setState({ fuelCardVisible: true });
        }

        if (hasFeature('fleetEld') && !this.state.ELDVisible && getPermissionValue('Compliance') !== 'None') {
            this.setState({ ELDVisible: true });
        }
    }

    scrollToRef = (section) => {
        // $FlowFixMe
        document.getElementsByTagName('main')[0].scrollTo({
            top: section.current.offsetTop - 120,
            behavior: 'smooth',
        });
    }

    handleTabChange = (value) => {
        this.setState({ tabValue: value });
        switch (value) {
        case 'info':
            this.scrollToRef(this.infoRef);
            break;
        case 'updates':
            this.scrollToRef(this.updatesRef);
            break;
        case 'installation':
            this.scrollToRef(this.installationRef);
            break;
        case 'associations':
            this.scrollToRef(this.associationsRef);
            break;
        case 'input':
            this.scrollToRef(this.inputsRef);
            break;
        case 'fuel':
            this.scrollToRef(this.fuelCardRef);
            break;
        case 'compliance':
            this.scrollToRef(this.complianceRef);
            break;
        case 'safety':
            this.scrollToRef(this.safetyRef);
            break;
        case 'maintenance':
            this.scrollToRef(this.maintenance);
            break;
        default:
        }
    }

    handleColorChange = (key, value) => {
        if (!value.hex) return;
        this.setState({ [key]: value.hex.substr(1) });
    }

    setLocalVars = () => {
        if (this.infoRef && this.infoRef.current) this.scrollToRef(this.infoRef);
        this.anchorEl = { miles: null, hours: null };
    }

    clearForm() {
        this.setLocalVars();
        c.fuelCardInput.id = '';
    }

    addChip = () => {
        let uniqueChip = true;
        const { selectedTag, chipData } = this.state;
        let trimmedTag = '';

        if (selectedTag) {
            trimmedTag = selectedTag.trim();
        }

        if (!trimmedTag) return;
        chipData.some((chip) => {
            if (chip.label.toLowerCase() === trimmedTag.toLowerCase()) {
                uniqueChip = false;
                return true;
            }
            return false;
        });

        if (uniqueChip) {
            let key = 0;
            if (chipData.length > 0) {
                for (let i = 0; i <= chipData.length; i += 1) {
                    const chipMatchingGivenKey = chipData.filter(chip => chip.key === i);
                    if (chipMatchingGivenKey.length === 0) {
                        key = i;
                        break;
                    }
                }
            }
            chipData.push({ key, label: trimmedTag.toUpperCase() });
            c.tagsFilter.showSelection = false;
            this.setState({
                chipData, tags: '', selectedTag: '',
            }, () => { c.tagsFilter.showSelection = true; });
        }
    };

    handleChipDelete = d => () => {
        this.setState((state) => {
            const chipData = [...state.chipData];
            const chipToDelete = chipData.indexOf(d);
            chipData.splice(chipToDelete, 1);
            c.tagsFilter.showSelection = false;
            return { chipData };
        }, () => { c.tagsFilter.showSelection = true; });
    };

    renderClearButton = property => (
        <Button
            variant="contained"
            color="primary"
            className={this.props.classes.clearButton}
            onClick={this.clearClick(property)}
            data-qa={`${property}_clearBtn`}
        >
        CLEAR
        </Button>
    )

    clearClick = elementName => () => {
        if (elementName === 'deviceSerialNumber') {
            this.setState({
                ...c.initialClrState,
                ...c.safetyProfileInitialState,
                showCopyContent: false,
            });

            if (this.initialDataObjForRetrieval.trackingDeviceSerial === null ||
                this.initialDataObjForRetrieval.trackingDeviceSerial === undefined) {
                this.initialDataObjForRetrieval.trackingDeviceSerial = '';
            }
        } else if (elementName === 'fuelCardAccountId') {
            this.setState({ [elementName]: '', fuelCardId: '' });
        } else if (elementName.includes('inputsFilter')) {
            const which = elementName.charAt(elementName.length - 1);
            this.setState({
                [`input${which}HighName`]: null,
                [`deviceInput${which}`]: '',
                [`input${which}LowStateName`]: '',
                [`input${which}HighStateName`]: '',
            });
        } else if (elementName === 'selectedDashcam') {
            this.setState({
                selectedDashcam: null,
                dashcamIdentifier: '',
                showCamCoachCopyContent: false,
            });
        }
        this.setState({ [elementName]: '' });
    }

    trackingDistanceAndEngineHoursFieldsShowHide = () => {
        this.trackingDistanceFields.showEstTotalDistance = !this.state.trueOdometerSourceOn;
        this.trackingDistanceFields.showGPSTrackedDistance = !this.state.trueOdometerSourceOn;
        this.trackingDistanceFields.showAssetDistanceSum = this.state.trueOdometerSourceOn;

        this.trackingEngineHoursFields.showInitialEngineHours =
            !this.state.trueEngineTimeSourceOn;
        this.trackingDistanceFields.showInitialHubometer =
            !this.state.trueOdometerSourceOn;
        this.trackingEngineHoursFields.showDeviceTrackedEngHours
            = !this.state.trueEngineTimeSourceOn;
        this.trackingEngineHoursFields.showEstTotalHours = !this.state.trueEngineTimeSourceOn;
        this.trackingEngineHoursFields.showTrueEngineHours = this.state.trueEngineTimeSourceOn;

        this.trackingDistanceFields.showOnlyAssetDistanceSum
            = this.state.trueOdometerSourceOn;
    };

    renderEstTotalData = (type) => {
        let offsetDiff = 0;
        let estTotal = 0;
        let unit = 'hrs';

        if (type === 'miles') {
            const { assetDistanceSum, stateTotalDistance, odometerAdjustment } = this.state;

            /* since asset distance sum is 0 or not defined, we will use stateTotalDistance.
            (which is initalHubometer + assetDistance + odometerAdjustment [if any]) */
            estTotal = getCorrectDistance(((!assetDistanceSum) ? stateTotalDistance : assetDistanceSum) / 1000, 'km').toFixed(1);
            if (odometerAdjustment) offsetDiff = +getCorrectDistance(odometerAdjustment / 1000, 'km').toFixed(1);
            unit = this.distanceString.short;
        } else {
            const {
                engineTimeSum, engineTimeAdjustment, stateTotalEngineTime,
            } = this.state;

            estTotal = (!engineTimeSum) ? stateTotalEngineTime : engineTimeSum;
            if (engineTimeAdjustment) offsetDiff = engineTimeAdjustment;
        }

        return (
            <span data-qa={`${type}_estTotalData`}>{(offsetDiff !== 0) ? `${estTotal} (offset ${offsetDiff > 0 ? '+' : ''}${offsetDiff.toFixed(1)}${unit})` : estTotal}</span>
        );
    }

    isFl360ProTier = () => hasFeature('fleet.ProTier') && this.state.deviceModelCode === c.modelCode.fl360;

    isFl360 = () => this.state.isFl360DeviceLabel;

    renderHelpBtn = (type) => {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <IconButton
                    ref={(node) => { this.anchorEl[type] = node; }}
                    data-type={type}
                    className={classes.iconBtn}
                    disableRipple
                    onMouseEnter={() => this.handleHelpPopover(type)}
                    onMouseLeave={() => this.closePopOver(type)}
                    data-qa={`${type}_estTotalHelpBtn`}
                    size="large"
                >
                    <SvgIcon fontSize="inherit">
                        <path d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
                    </SvgIcon>
                </IconButton>
                <Popover
                    style={{ pointerEvents: 'none' }}
                    open={this.state[`${type}TotalHelpOpen`]}
                    anchorEl={this.anchorEl[type]}
                    anchorReference="anchorEl"
                    onClose={() => this.closePopOver(type)}
                    disableRestoreFocus
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    data-qa={`${type}_estTotalHelpPopover`}
                >
                    <Paper className={classes.root} elevation={1}>
                        <Typography component="p" style={c.inlined.estHelpText}>
                            {this.getHelpText(type)}
                        </Typography>
                    </Paper>
                </Popover>
            </React.Fragment>
        );
    }

    closePopOver = (type) => {
        this.setState({ [`${type}TotalHelpOpen`]: false });
    }

    handleHelpPopover = (type) => {
        if (!this.state[`${type}TotalHelpOpen`]) {
            this.setState({ anchorElAvailable: true }, () => {
                this.setState({ [`${type}TotalHelpOpen`]: !this.state[`${type}TotalHelpOpen`] });
            });
        }
    }

    renderSelectBox = (filter, width = '170px', forceReloadOption = null) => {
        const { property, filterTitle, selectStyles } = filter;
        let ddlStyles = {
            control: style => ({
                ...style,
                display: 'flex',
                width,
                borderWidth: '0 0 1px 0',
                borderRadius: 0,
                borderColor: 'rgb(211, 215, 219)',
                boxShadow: '0 0 0 0',
            }),
            selectLabel: c.inlined.selectLbl,
        };

        let selectedOptions = null;
        if (this.state[property]) {
            selectedOptions = {
                value: this.state[property],
                label: filter.showToolTip ?
                    <Tooltip title={this.state[filter.label] || ''} placement="bottom-start" className={extStyles.tooltipCursorPointer}>
                        <span>{this.state[filter.label]}</span>
                    </Tooltip> : this.state[filter.label],
            };
        }

        if (!this.allowSelectBoxReassignment) {
            const extras = { allowedFreeText: false };
            if (this.freeTextSelectBoxList.indexOf(property) > -1) extras.allowedFreeText = true;
            this.initialDataObjForRetrieval[property] = { ...(selectedOptions || {}), ...extras };
        }

        if (selectStyles) ddlStyles = { ...ddlStyles, ...selectStyles };

        return (
            <React.Fragment>
                <InputLabel
                    htmlFor={filterTitle}
                    disableAnimation
                    style={ddlStyles.selectLabel}
                    data-qa={`${property}_asyncDdlLabel`}
                >{selectedOptions && selectedOptions.label ? filterTitle : ''}
                </InputLabel>
                <AsyncSelectWrapper
                    handleDelete={() => {}}
                    loadOptions={(val, options) => this.filterOptions(filter, val, options)}
                    onItemSelected={selected => this.onFilterSelected(filter, selected)}
                    selectedOptions={selectedOptions}
                    initialOptions={this.initialDataObjForRetrieval[property]}
                    title={filterTitle}
                    selectStyles={ddlStyles}
                    disableSearch={filter.disableSearch}
                    disableChips={filter.disableChips}
                    showSelection={filter.showSelection}
                    inputProps={{ id: filterTitle }}
                    key={!forceReloadOption ? property : JSON.stringify(forceReloadOption)}
                />
            </React.Fragment>
        );
    }

    filterOptions = (filter, inputValue, loadedOptions) => new Promise((resolve, reject) => {
        this.allowSelectBoxReassignment = true;
        if (inputValue) {
            const { property, label } = filter;
            const tempData = {};
            if (property === 'fuelCardAccountId' && this.freeTextSelectBoxList.indexOf(property) > -1) {
                c.fuelCardInput.id = '';
                tempData.fuelCardId = '';
                tempData.fuelCardNumber = '';
            }

            this.setState({
                [property]: inputValue,
                [label]: inputValue,
                ...tempData,
            });
        }

        if (!filter.filterOptions) {
            filter.filterOptionsWithParams(loadedOptions.length, filter.perPage, inputValue)
                .then((res: Response) =>
                    this.handleFilterResponse(filter, loadedOptions, res, resolve, reject));
        } else {
            filter.filterOptions().then((res: Response) =>
                this.handleFilterResponse(filter, loadedOptions, res, resolve, reject));
        }
    });

    handleFilterResponse = (filter, loadedOptions, res, resolve, reject) => {
        const hasMore = loadedOptions.length + res[filter.dataCountParam || 'count'] < res[filter.dataTotalParam];

        if (res) {
            const resData = res[filter.dataArrayParam || 'content'];

            if (resData) {
                if (filter.property === 'operatorId') {
                    resData.map((driver: Response) => {
                        // eslint-disable-next-line no-param-reassign
                        driver.name = !driver.name ? `${driver.firstName} ${driver.lastName}` : driver.name;
                        return true;
                    });
                }

                let uniqueAry = [];
                if (loadedOptions.length > 0) {
                    uniqueAry = loadedOptions.map(d => d.label);
                }

                const options = [];
                if (filter.renderEmpty === true && loadedOptions.length === 0) {
                    options.push({
                        label: `Select ${filter.filterTitle}`, value: '', isDisabled: true,
                    });
                }

                resData.push(...c.systemInputType);
                resData.map((data: Response) => {
                    const label = data[filter.datumLabelParam || 'name'] || '';
                    if (uniqueAry.indexOf(label) === -1) {
                        uniqueAry.push(label);
                        options.push({
                            label: filter.showToolTip ?
                                <Tooltip title={label || ''} placement="bottom-start" className={extStyles.tooltipCursorPointer}>
                                    <span>{label}</span>
                                </Tooltip>
                                : label,
                            value: data[filter.datumValueParam || 'id'],
                            data,
                        });
                    }
                    return false;
                });

                resolve({ options, hasMore });
            } else reject();
        } else reject();
    }

    onFilterSelected = (filter, selected) => {
        this.allowSelectBoxReassignment = false;
        if (Array.isArray(selected) && selected.length === 0) return;

        const { data, value, label } = selected;
        let tempData = {};
        if (filter.property === 'deviceSerialNumber') {
            let isXirgo = false;
            let isFl360deviceLabel = false;

            if (data && data.deviceTypeCode && data.deviceTypeCode.toLowerCase().includes('xirgo')) {
                isXirgo = true;
            } else if (data && data.deviceModelLabel && data.deviceModelLabel.toLowerCase().includes('xirgo')) {
                isXirgo = true;
            } else if (data && data.deviceTypeLabel && data.deviceTypeLabel.toLowerCase().includes('xirgo')) {
                isXirgo = true;
            }

            if (isXirgo && data && data.deviceModelLabel &&
                data.deviceModelLabel.includes(c.modelCode.fl360modelLabel)) {
                isFl360deviceLabel = true;
            }

            let showCopyContent = false;
            if (data) showCopyContent = true;
            tempData = {
                safetyVisible: isXirgo,
                deviceIdProperty: value,
                showCopyContent,
                isFl360DeviceLabel: isFl360deviceLabel,
            };
        } else if (filter.property === 'fuelCardAccountId') {
            c.fuelCardInput.id = value;
            tempData = { fuelCardId: '', fuelCardNumber: '' };
        } else if (filter.property.indexOf('input') > -1) {
            const inputIndex = filter.property.slice(5, 6);
            if (data && data.type === 'system') {
                tempData = {
                    [`input${inputIndex}HighStateName`]: data.highNameValue,
                    [`input${inputIndex}LowStateName`]: data.lowNameValue,
                    [`deviceInput${inputIndex}`]: data.code,
                    [`input${inputIndex}Type`]: 'system',
                };
            } else {
                tempData = {
                    [`input${inputIndex}HighStateName`]: 'On',
                    [`input${inputIndex}LowStateName`]: 'Off',
                    [`deviceInput${inputIndex}`]: customHiOnloOff,
                    [`input${inputIndex}Type`]: 'custom',
                };
                if (this.state[`input${inputIndex}HighName`] !== value && ((this.state[`deviceInput${inputIndex}`] === customHiOffloOn) || (this.state[`deviceInput${inputIndex}`] === customHiOnloOff))) {
                    tempData.hasCustomInputChanged = true;
                }
            }
        }

        this.setState({
            ...tempData,
            [filter.property]: value,
            [filter.label]: label,
        });
    }

    renderSelect = (
        id: string, label: string, onChange: Function, value: string,
        choices: any, className: any, disabled: boolean = false,
    ) => (
        <TextField
            id={id}
            select
            label={label}
            className={className}
            value={parseInt(value, 10) || value || ''}
            onChange={onChange}
            disabled={disabled}
            data-qa={`${id}_ddl`}
        >
            {choices.map((option, i) => {
                const key = option.value + i.toString();
                return (<MenuItem key={key} data-qa={`${id}_ddl_${option.value}`} value={option.value}>{option.label}</MenuItem>);
            })}
        </TextField>
    );

    renderRadio = (section, name) => (
        <label htmlFor={section} data-qa={`${section}_rBtnContainer`}>
            <Radio
                checked={section === 'required' ? this.state.eldRequired : !this.state.eldRequired}
                value={name}
                id={section}
                color="primary"
                data-qa={`${section}_rBtn`}
            />
            <span className={this.props.classes.radioTitle} data-qa={`${section}_rBtnLabel`}>{name}</span>
        </label>
    );

    handleChange = ele => (e) => {
        let { value } = e.target;
        let updateState = true;
        if (['attributeCapacity'].indexOf(ele) > -1) {
            if (value === '' || /^\d+$/.test(value)) {
                value = (parseInt(Math.abs(value), 10) || 0);
            } else updateState = false;
        } else if ([
            'odometerAdjustmentVal', 'engineHourAdjustmentVal',
            'intialHubometer', 'initialEngineTime', 'highSpeed', 'highRPM'].indexOf(ele) > -1) {
            if (value === '' || /^\d+(\.\d{1,2})?$/.test(value)) {
                value = (Math.abs(value) || 0);
            } else updateState = false;
        }

        if (updateState) this.setState({ [ele]: value.toString() });
    }

    handleInputChange = prop => (e) => {
        const { value } = e.target;
        let temp = {};
        this.setState({ hasCustomInputChanged: true });
        const input = prop.substr(0, 6);
        const deviceInput = `deviceI${prop.substr(1, 5)}`;
        let deviceInputValue = '';
        const opposites = {
            Open: 'Closed',
            Closed: 'Open',
            On: 'Off',
            Off: 'On',
            Active: 'Inactive',
            Inactive: 'Active',
        };

        if (prop.substr(6) === 'LowStateName') {
            if (['Open', 'On', 'Active'].indexOf(value) > -1) {
                deviceInputValue = customHiOffloOn
            } else {
                deviceInputValue = customHiOnloOff
            }
            temp = { [`${input}HighStateName`]: opposites[value] };
        } else if (prop.substr(6) === 'HighStateName') {
            if (['Open', 'On', 'Active'].indexOf(value) > -1) {
                deviceInputValue = customHiOnloOff
            } else {
                deviceInputValue = customHiOffloOn
            }
            temp = { [`${input}LowStateName`]: opposites[value] };
        }
        this.setState({ ...temp, [prop]: value, [deviceInput]: deviceInputValue });
    }

    handleIconChange = (key, value) => {
        if (!key) return;
        let newValue = value;

        if (!value) newValue = '';
        this.setState({ [key]: newValue });
    }

    handleDateChange = (prop, date) => {
        this.setState({
            [`${prop}AdjustmentDate`]: date,
            [`${prop}DatePickerOpen`]: false,
        });
    };

    handleCheckChange = name => (e) => {
        const { checked } = e.target;
        const temp = {};
        let num = 0;

        if (name === 'highSpeedChecked') {
            if (!checked) {
                num = 1;
                temp.highSpeed = '0'
            }
        }

        if (name === 'highRPMChecked') {
            if (!checked) {
                num = 2;
                temp.highRPM = '0'
            }
        }

        if (name === 'hardBrakingChecked') {
            if (!checked) {
                num = 3;
            }
        }

        if (name === 'hardAccelerationChecked') {
            if (!checked) {
                num = 4;
            }
        }

        this.setState({ ...temp, [name]: checked, [`wb${num}Checked`]: false });
    }

    deleteObjectKeys = (obj, ...keysToDelete) => {
        const keys = keysToDelete.length ? keysToDelete : ['ELDVisible', 'show', 'isColorDisable', 'deleteDialogOpen', 'errorDialogOpen', 'successDialogOpen', 'fuelCardVisible', 'fuelTypes', 'attributeTypes', 'odometerAdjustmentHistory', 'safetyVisible', 'selectedTag', 'assetTypes', 'engineTimeAdjustment', 'engineTimeAdjustmentHistory', 'chipData', 'assetName', 'attributeCapacity', 'attributeFuelType', 'attributeColor', 'attributeIcon', 'errorMessage', 'errorTitle', 'loading', 'milesTotalHelpOpen', 'hoursTotalHelpOpen', 'selectedFilters', 'selectedAsset', 'validationDialogBox', 'editMode', 'stateTotalDistance', 'stateTrackedEngineTime', 'stateTotalEngineTime', 'assetDistanceSum', 'engineTimeSum', 'engineTime', 'showCopyContent', 'contentCopyTitle', 'tabValue', 'maintenanceVisible', 'hardAccelerationChecked', 'hardBrakingChecked', 'wb1Checked', 'wb2Checked', 'wb3Checked', 'wb4Checked', 'highRPMChecked', 'highSpeedChecked', 'attributeNotes', 'installationSection'];

        // eslint-disable-next-line no-param-reassign
        keys.forEach(i => delete obj[i]);
    }

    validateForm = (d) => {
        if (!d.assetName) {
            this.handleDialogOpen('error', 'Please add an Asset Name.');
            return false;
        } else if (!d.attributeIcon) {
            this.handleDialogOpen('error', 'Please select an Asset Icon.');
            return false;
        } else if (!d.assetTypeIdProperty) {
            this.handleDialogOpen('error', 'Please select an Asset Type.');
            return false;
        } else if (getAssetTypeCodeProperty(d.assetTypeIdProperty, this.state.assetTypes) !== 'asset.type.localFleet.equipment' && this.state.ELDVisible === true && (!d.vin || (d.vin.length !== 17 && d.vin.length !== 18))) {
            this.handleDialogOpen('error', 'VIN must be from 17 to 18 characters in length.');
            return false;
        } else if (getAssetTypeCodeProperty(d.assetTypeIdProperty, this.state.assetTypes) !== 'asset.type.localFleet.equipment' && this.state.ELDVisible === true && d.vin.match(/[IOQ]/gi)) {
            this.handleDialogOpen('error', 'VIN may not contain the characters I, O, or Q.');
            return false;
        } else if (Math.abs(parseInt(d.odometerAdjustmentVal, 10) || 0) > 0) {
            if (!d.odometerAdjustmentDate) {
                this.handleDialogOpen('error', 'Please select odometer adjustment date.');
                return false;
            }
        } else if (Math.abs(parseInt(d.engineHourAdjustmentVal, 10) || 0) > 0) {
            if (!d.engineHourAdjustmentDate) {
                this.handleDialogOpen('error', 'Please select engine hour adjustment date.');
                return false;
            }
        } else if (d.attributeCapacity !== '' && !(parseInt(d.attributeCapacity, 10) >= 0 && parseInt(d.attributeCapacity, 10) <= 200)) {
            this.handleDialogOpen('error', 'Fuel tank capacity must be within the range of 1 to 200.');
            return false;
        } else if (d.fuelCardAccountId && !(/^\d+$/.test(d.fuelCardAccountId))) {
            this.handleDialogOpen('error', 'Account number must be a numeric data.');
            return false;
        } else if (d.fuelCardAccountId && (/^\d+$/.test(d.fuelCardAccountId)) && hasFeature('fleetFuelCard') && d.fuelCardAccountId.length > 9) {
            this.handleDialogOpen('error', 'Account number length must be in the range of 1-9.');
            return false;
        } else if (d.fuelCardAccountId && (/^\d+$/.test(d.fuelCardAccountId)) && hasFeature('fleetWexFuelCard') && d.fuelCardAccountId.length > 19) {
            this.handleDialogOpen('error', 'Account number length must be in the range of 1-19.');
            return false;
        } else if (d.fuelCardAccountId && (/^\d+$/.test(d.fuelCardAccountId)) && hasFeature('fleetFleetCorFuelCard') && d.fuelCardAccountId.length > 20) {
            this.handleDialogOpen('error', 'Account number length must be in the range of 1-20.');
            return false;
        } else if (d.fuelCardNumber && (hasFeature('fleetFuelCard') || hasFeature('fleetWexFuelCard')) && !(/^\d+$/.test(d.fuelCardNumber))) {
            this.handleDialogOpen('error', 'Fuel card must be a numeric data.');
            return false;
        } else if (d.fuelCardNumber && (/^\d+$/.test(d.fuelCardNumber)) && hasFeature('fleetFuelCard') && d.fuelCardNumber.length > 6) {
            this.handleDialogOpen('error', 'Fuel card length must be in the range of 1-6.');
            return false;
        } else if (d.fuelCardNumber && (/^\d+$/.test(d.fuelCardNumber)) && hasFeature('fleetWexFuelCard') && d.fuelCardNumber.length !== 5) {
            this.handleDialogOpen('error', 'Fuel card length must be 5 digits.');
            return false;
        } else if (d.fuelCardNumber && (/^\d+$/.test(d.fuelCardNumber)) && hasFeature('fleetFleetCorFuelCard') && d.fuelCardNumber.length > 50) {
            this.handleDialogOpen('error', 'Fuel card length must be in the range of 1-50.');
            return false;
        }
        return true;
    }

    handleSaveClick = () => {
        const d = Object.assign({}, this.state);
        if (!this.validateForm(d)) return;
        this.setState({ loading: true });

        d.name = d.assetName.trim();
        d.assetTypeIdProperty = parseInt(d.assetTypeIdProperty, 10) || null;
        d.assetTypeId = d.assetTypeIdProperty;
        d.assetTypeCodeProperty = getAssetTypeCodeProperty(d.assetTypeId, this.state.assetTypes);
        d.deviceIdProperty = d.deviceSerialNumber === '' || d.deviceSerialNumber === null ? null : d.deviceIdProperty;
        d.initialEngineTime = (parseFloat(d.initialEngineTime) * 3600000) || null;
        d.engineHours = d.initialEngineTime;
        d.odometerAdjustmentVal = convertBackToMeters(parseFloat(d.odometerAdjustmentVal)) || null;
        d.engineHourAdjustmentVal = (parseFloat(d.engineHourAdjustmentVal) * 3600000) || null;
        d.intialHubometer = convertBackToMeters(parseFloat(d.intialHubometer)) || null;
        d.intialOdometer = d.intialHubometer;
        d.year = parseInt(d.year, 10) || null;
        d.input1LowName = d.input1HighName;
        d.input2LowName = d.input2HighName;
        d.input3LowName = d.input3HighName;
        d.input4LowName = d.input4HighName;
        d.hasCustomInputChanged = d.hasCustomInputChanged;
        d.lastUpdated = new Date().toISOString();
        let tags = '';
        d.chipData.forEach((chip) => {
            tags = `${tags}public:${chip.label},`;
        });
        d.tags = (tags !== '') ? tags.slice(0, -1) : tags;

        const attributes = [{
            attributeTypeId: this.findAttributeIdByName('fuelTankCapacity'),
            value: parseFloat(d.attributeCapacity) || 0,
        },
        {
            attributeTypeId: this.findAttributeIdByName('fuelType'),
            value: d.attributeFuelType,
        },
        {
            attributeTypeId: this.findAttributeIdByName('localfleet.assetLabelColor'),
            value: d.attributeColor,
        },
        {
            attributeTypeId: this.findAttributeIdByName('localfleet.assetCustomIcon'),
            value: `localfleet.assetCustomIcon.${d.attributeIcon}`,
        },
        {
            attributeTypeId: this.findAttributeIdByName('maxSpeedThreshold'),
            value: reverseUnitValAsPerUserSetting(Number(d.highSpeed), 'round').toString(),
        },
        {
            attributeTypeId: this.findAttributeIdByName('rpmThreshold'),
            value: this.fetchSafetyProfileTextValue(d.highRPM, '0'),
        },
        {
            attributeTypeId: this.findAttributeIdByName('accelThreshold'),
            value: d.hardAccelerationChecked ? 1 : 0,
        },
        {
            attributeTypeId: this.findAttributeIdByName('decelThreshold'),
            value: d.hardBrakingChecked ? 1 : 0,
        },
        {
            attributeTypeId: this.findAttributeIdByName('speedViolationBuzzer'),
            value: d.wb1Checked,
        },
        {
            attributeTypeId: this.findAttributeIdByName('rpmViolationBuzzer'),
            value: d.wb2Checked,
        },
        {
            attributeTypeId: this.findAttributeIdByName('accelViolationBuzzer'),
            value: d.wb4Checked,
        },
        {
            attributeTypeId: this.findAttributeIdByName('decelViolationBuzzer'),
            value: d.wb3Checked,
        },
        {
            attributeTypeId: this.findAttributeIdByName('localfleet.assetNote'),
            value: d.attributeNotes,
        },
        ];
        d.attributesJSON = JSON.stringify(attributes);
        this.deleteObjectKeys(d);

        if (d.deviceSerialNumber
            && this.initialDataObjForRetrieval.trackingDeviceSerial !== d.deviceSerialNumber) {
            getDeviceSerial(0, 25, d.deviceSerialNumber).then((res: Response) => {
                if (res.success && res.total > 0) {
                    this.manageActions(d, this.state.editMode ? 'edit' : 'add');
                } else {
                    this.handleDialogOpen('error', 'This device serial is already assigned to some other asset.');
                    return false;
                }
                return true;
            });
        } else {
            this.manageActions(d, this.state.editMode ? 'edit' : 'add');
        }
    }

    showDashcamErrorMsg = (error) => {
        if (error && error.message.indexOf('not found error:') > -1) {
            return this.handleDialogOpen('error', `${this.state.dashcamIdentifier} is not a valid IMEI. The IMEI can be found on the CamCoach label.`, 'Invalid CamCoach IMEI');
        }
        return this.handleDialogOpen('error', 'Error occurred when associating Asset with CamCoach, please try again later.', 'Asset CamCoach Association Error');
    }

    addDashcam = (id, name, apiResponseMessage, msg) => {
        let { dashcamIdentifier = '' } = this.state;
        dashcamIdentifier = dashcamIdentifier.trim();
        if (this.state.apiDashcamIdentifier !== dashcamIdentifier && dashcamIdentifier !== '') {
            const data = { assetId: id, dashcamIdentifier, name };
            if (this.showLMDashcam()) {
                data.dashcamDeviceId = dashcamIdentifier;
                delete data.dashcamIdentifier;
            }
            createAssetDashcam(data).then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.handleDialogOpen('success', apiResponseMessage, msg);
                } else {
                    this.showDashcamErrorMsg(res);
                }
            }).catch(error => this.showDashcamErrorMsg(error));
        } else if (dashcamIdentifier === '' && this.state.apiDashcamIdentifier !== '') {
            deleteAssetDashcam(id).then((res) => {
                if (res.status === 200 || res.status === 204) {
                    this.handleDialogOpen('success', apiResponseMessage, msg);
                } else {
                    this.showDashcamErrorMsg(res);
                }
            }).catch(error => this.showDashcamErrorMsg(error));
        } else if (this.state.apiDashcamIdentifier === dashcamIdentifier) {
            this.handleDialogOpen('success', apiResponseMessage, msg);
        }
    }

    errorResponce=(res) => {
        if (res.errors && res.errors.name && res.errors.name.toLowerCase() === 'property \'name\' must be unique') {
            return 'Asset name already exists, please use a different name.';
        } return res.msg;
    }

    manageActions = (dataToSend, action) => {
        let dynamicParams = {};

        if (hasFeature('fleetWexFuelCard')) {
            dynamicParams = {
                wexFuelCardAccountId: dataToSend.fuelCardAccountId,
                wexFuelCardNumber: dataToSend.fuelCardId,
            };
        } else if (hasFeature('fleetFleetCorFuelCard')) {
            dynamicParams = {
                fleetCorAccountCode: dataToSend.fuelCardAccountId,
                fleetCorVehicleId: dataToSend.fuelCardId,
            };
        }

        if (!hasFeature('fleetFuelCard')) {
            this.deleteObjectKeys(dataToSend, 'fuelCardAccountId', 'fuelCardId', 'fuelCardNumber');
        }

        if (action === 'edit' || action === 'delete') {
            editAsset({ ...dataToSend, ...dynamicParams }).then((res) => {
                if (!res.success) {
                    this.handleDialogOpen('error', this.errorResponce(res), `${action === 'edit' ? 'Save' : 'Delete'} Failed`);
                } else if (action === 'edit') {
                    if (this.id && (this.showDashcam() || this.showLMDashcam())) {
                        this.addDashcam(this.id, dataToSend.name, res.msg, 'Asset Updated Successfully');
                    } else {
                        this.handleDialogOpen('success', res.msg, 'Asset Updated Successfully');
                    }
                    const params = {
                        feature: 'FLEET_Asset',
                        attributeIcon: this.state.attributeIcon ? 'true' : 'false',
                        assetGroup: (this.initialDataObjForRetrieval.groupId && this.initialDataObjForRetrieval.groupId.label) ? 'true' : 'false',
                        labelColor: this.state.attributeColor ? 'true' : 'false',
                        VIN: this.state.vin ? 'true' : 'false',
                        year: this.state.year ? 'true' : 'false',
                        model: this.state.model ? 'true' : 'false',
                        make: this.state.make ? 'true' : 'false',
                        driver: this.state.name !== 'null null' ? 'true' : 'false',
                        fuelType: this.state.attributeFuelType ? 'true' : 'false',
                    };
                    analytics.track('EDIT_ASSET', params);
                    const changedDataArr = [];
                    let changedData = '';
                    Object.keys(this.state.mixpanelOriginalState).forEach((v) => {
                        if (this.state[v] !== this.state.mixpanelOriginalState[v]) {
                            if (v === 'vin') {
                                changedDataArr.push('VIN');
                            } else if (v === 'attributesJSON') {
                                JSON.parse(this.state.mixpanelOriginalState.attributesJSON)
                                    .forEach((attr) => {
                                        if (attr.attributeTypeName === 'localfleet.assetCustomIcon') {
                                            const [, , temp] = attr.value.split('.');
                                            const assetIcon = temp;
                                            if (this.state.attributeIcon
                                                !== assetIcon) {
                                                changedDataArr.push('attributeIcon');
                                            }
                                        }
                                    });
                            } else changedDataArr.push(v);
                        }
                    });
                    changedData = changedDataArr.join(', ');
                    if (changedData.length !== 0) {
                        analytics.track('CHANGE_ASSET', { changedData, feature: 'FLEET_Asset' });
                    }
                } else {
                    this.handleDialogClose('success');
                }
            });
        } else {
            this.deleteObjectKeys(dataToSend, 'id');
            addAsset({ ...dataToSend, ...dynamicParams }).then((res) => {
                if (!res.success) {
                    this.handleDialogOpen('error', this.errorResponce(res), 'Save Failed');
                } else {
                    if (res.data && res.data.globalId && this.state.dashcamIdentifier) {
                        this.addDashcam(res.data.globalId, res.data.name, res.msg, 'Asset Added Successfully');
                    } else {
                        this.handleDialogOpen('success', res.msg, 'Asset Added Successfully');
                    }
                    if (!(this.showDashcam() || this.showLMDashcam())) this.handleDialogOpen('success', res.msg, 'Asset Added Successfully');
                    const params = {
                        feature: 'FLEET_Asset',
                        attributeIcon: this.state.attributeIcon ? 'true' : 'false',
                        assetGroup: (this.initialDataObjForRetrieval.groupId && this.initialDataObjForRetrieval.groupId.label) ? 'true' : 'false',
                        labelColor: this.state.attributeColor ? 'true' : 'false',
                        VIN: this.state.vin ? 'true' : 'false',
                        year: this.state.year ? 'true' : 'false',
                        model: this.state.model ? 'true' : 'false',
                        make: this.state.make ? 'true' : 'false',
                        driver: this.state.name ? 'true' : 'false',
                        fuelType: this.state.attributeFuelType ? 'true' : 'false',
                    };
                    analytics.track('ADD_ASSET', params);
                }
            });
        }
    }

    deleteHandler = () => {
        this.setState({ loading: true }, () => {
            const d = Object.assign({}, this.state);
            d.name = `${d.assetName}-${d.id}-deleted`;
            this.deleteObjectKeys(d);

            d.active = false;
            d.groupId = -1;
            d.assetTypeCodeProperty =
                getAssetTypeCodeProperty(d.assetTypeId, this.state.assetTypes);
            d.groupName = null;
            d.deviceIdProperty = 0;
            d.operatorIdProperty = null;
            d.lastUpdated = new Date().toISOString();

            d.assetTypeIdProperty = parseInt(d.assetTypeIdProperty, 10) || null;
            d.assetTypeId = d.assetTypeIdProperty;
            d.initialEngineTime = (parseFloat(d.initialEngineTime) * 3600000) || null;
            d.odometerAdjustmentVal =
                convertBackToMeters(parseFloat(d.odometerAdjustmentVal)) || null;
            d.engineHourAdjustmentVal = (parseFloat(d.engineHourAdjustmentVal) * 3600000) || null;
            d.intialHubometer = convertBackToMeters(parseFloat(d.intialHubometer)) || null;
            d.intialOdometer = d.intialHubometer;
            d.year = parseInt(d.year, 10) || null;

            this.manageActions(d, 'delete');
            this.handleDialogClose('delete');
            analytics.track('DELETE_ASSET', { feature: 'FLEET_Asset' });
        });
    }

    copyText = (filter) => {
        const { contentCopyTitle } = this.state;
        if (filter.property && this.state[filter.property] && contentCopyTitle !== 'Copied') {
            copyTextToClipboard(this.state[filter.property]);
        } else if (filter.property === 'camCoachNumber' && this.state.selectedDashcam && contentCopyTitle !== 'Copied') {
            copyTextToClipboard((this.state.selectedDashcam).value);
        }
        this.setState({ contentCopyTitle: 'Copied' });
    }

    handleDialogOpen = (type, dMessage, dTitle = 'Invalid Data') => {
        const d = {};
        d[`${type}DialogOpen`] = true;
        if (type !== 'delete') {
            d.errorMessage = dMessage;
            d.errorTitle = dTitle;
            d.loading = false;
        }
        if (!this.state[`${type}DialogOpen`]) {
            this.setState(d);
        }
    }

    handleDialogClose = (type) => {
        if (this.state[`${type}DialogOpen`]) {
            this.setState({ [`${type}DialogOpen`]: false });
        }

        if (type === 'delete') {
            this.isDelete = true;
        } else {
            this.setState({ loading: false });
        }

        if (type === 'success') {
            this.clearForm();
            if (this.isDelete) {
                this.props.history.push('/assets');
            } else {
                this.props.history.goBack();
            }
        }
    }

    calendarCloseHandler = (prop) => {
        this.setState({
            [`${prop}DatePickerOpen`]: false,
            [`${prop}PickedDate`]: this.state.engineHourAdjustmentDate,
        })
    }

    engineHourCalendarOpenHandler = () => {
        if (!this.state.engineHourPickedDate) {
            this.setState({ engineHourPickedDate: moment() })
        }
        this.setState({ engineHourDatePickerOpen: true })
    }

    odometerCalendarOpenHandler = () => {
        if (!this.state.odometerPickedDate) {
            this.setState({ odometerPickedDate: moment() })
        }
        this.setState({ odometerDatePickerOpen: true })
    }

    engineHourRenderDays = (date, selectedDays, pickersDayProps) => {
        const { engineHourPickedDate } = this.state;
        return this.renderDays(engineHourPickedDate, selectedDays, pickersDayProps);
    };

    odometerRenderDays = (date, selectedDays, pickersDayProps) => {
        const { odometerPickedDate } = this.state;
        return this.renderDays(odometerPickedDate, selectedDays, pickersDayProps);
    };

    renderDays = (pickedDate, selectedDays, pickersDayProps) => {
        const isDaySelected = pickedDate
            ? moment(pickedDate).isSame(pickersDayProps.day, 'D')
            : false;
        return (
            <div>
                <PickersDay
                    {...pickersDayProps}
                    selected={isDaySelected}
                />
            </div>
        );
    };

    showDashcam = () => hasFeature('fleet.Dashcam');

    showLMDashcam = () => hasFeature('fleet.LightMetricsDashcam');

    dashcamFilterOptions = (filter: any, inputValue: any, loadedOptions: any) => (
        new Promise((resolve, reject) => {
            const perPage = filter.perPage || defaultPerPage;
            filter.filterOptions(loadedOptions.length, perPage, inputValue).then((response) => {
                const count = response[filter.dataCountParam || defaultDataCountParam];
                const total = response[filter.dataTotalParam || defaultDataTotalParam];
                const hasMore = loadedOptions.length + count < total;

                if (response) {
                    const responseData = response[filter.dataArrayParam || defaultDataArrayParam];
                    if (responseData && Array.isArray(responseData)) {
                        const option = [];
                        if (filter.renderEmpty === true && loadedOptions.length === 0) {
                            option.push({
                                label: `Select ${filter.filterTitle}`, value: '', isDisabled: true,
                            });
                        }
                        const optionData = responseData.map(datum => ({
                            value: datum[filter.datumValueParam || defaultDatumValueParam],
                            label: filter.showToolTip ?
                                <Tooltip
                                    title={datum[filter.datumLabelParam || defaultDatumLabelParam] || ''}
                                    placement="bottom-start"
                                    className={extStyles.tooltipCursorPointer}
                                >
                                    <span>
                                        {datum[filter.datumLabelParam || defaultDatumLabelParam]}
                                    </span>
                                </Tooltip>
                                : datum[filter.datumLabelParam || defaultDatumLabelParam],
                            data: datum,
                        }));
                        resolve({
                            options: [...option, ...optionData],
                            hasMore,
                        });
                    } else {
                        reject();
                    }
                } else {
                    reject();
                }
            });
        }));

    renderAssetsCard = (classes) => {
        const {
            attributeColor,
            attributeIcon,
            initialEngineTime,
            contentCopyTitle,
            showCamCoachCopyContent,
            showCopyContent,
            attributeNotes,
        } = this.state;

        const dashcamDevice = {
            filterOptions: getLMDevices,
            selectedOptions: [],
            filterType: 'select',
            filterTitle: 'CamCoach IMEI',
            selectPlaceholder: 'All Devices',
            property: 'id',
            dataArrayParam: 'data',
            datumValueParam: 'serialNumber',
            datumLabelParam: 'serialNumber',
            dataCountParam: 'count',
            propertyType: 'string',
            disableSearch: false,
            renderEmpty: true,
            showSelection: true,
            disableChips: true,
            perPage: 25,
            dataTotalParam: 'total',
        };

        const selectStyles = {
            control: style => ({
                ...style,
                display: 'flex',
                borderWidth: '0 0 1px 0',
                borderRadius: 0,
                borderColor: 'rgb(211, 215, 219)',
                boxShadow: 'none !important',
            }),
            valueContainer: selectStylesValue => ({ ...selectStylesValue, paddingLeft: '0px' }),
        };

        return (
            <div ref={this.infoRef} data-qa="info_section">
                <Paper className={classes.blankCard}>
                    <Grid container spacing={2} alignItems="center" className={extStyles.assetIDContainer}>
                        <Grid item xs={12} className={extStyles.gridItemPadding}>
                            <Typography className={classes.cardTitle}>Asset ID</Typography>
                        </Grid>
                        <Grid item xs={12} className={extStyles.gridPadding}>
                            <TextField
                                id="asset-id"
                                label="Asset Name*"
                                className={classes.input86p}
                                sx={{ paddingRight: '16px' }}
                                value={this.state.assetName}
                                inputProps={{ maxLength: 75 }}
                                onChange={this.handleChange('assetName')}
                                autoComplete="off"
                                data-qa="asset_name_txtBox"
                                onBlur={() => {
                                    this.setState({ assetName: this.state.assetName.trim() });
                                }}
                            />
                            {this.state.editMode && (
                                <div className={extStyles.messageText}>
                                    Moving your device to a new asset{this.renderHelpBtn('device')}
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12} className={extStyles.gridPadding}>
                            <TextField
                                value={attributeIcon}
                                onChange={e => this.handleIconChange('attributeIcon', e.target.value)}
                                className={`${classes.cardDrop} ${attributeIcon ? extStyles.assetIconContainer : ''}`}
                                label="Asset Icon*"
                                name="attributeIcon"
                                style={c.inlined.assetIcon}
                                select
                                data-qa="asset_icon_ddl"
                            >
                                {c.assetIcons.map((menu, i) => {
                                    const key = menu.value + i;
                                    return (
                                        <MenuItem className={extStyles.assetIconMenuItem} value={menu.value} key={key} data-qa={`asset_icon_ddl_value_${menu.value}`}>
                                            <div className={extStyles.assetIconWithText}>
                                                <div className={extStyles.assetIconWrapper}>
                                                    <img
                                                        src={getImage('asset_icon_backround')}
                                                        alt={menu.key}
                                                        style={{ height: 40 }}
                                                    />
                                                    <GetSvgIcon
                                                        type={menu.value}
                                                        className={extStyles.icon}
                                                        fillcolor="#fff"
                                                    />
                                                </div>
                                                <div className={extStyles.text}>
                                                    {menu.key}
                                                </div>
                                            </div>
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                            {this.renderSelect(
                                'asset-type',
                                'Asset Type*',
                                this.handleChange('assetTypeIdProperty'),
                                this.state.assetTypeIdProperty,
                                this.state.assetTypes,
                                classes.cardDrop,
                            )}
                        </Grid>
                        <Grid item xs={12} className={`${extStyles.gridNoPadding} ${classes.bp8px}`}>
                            <Grid container alignItems="center">
                                <Grid item xs={4}>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item className={extStyles.assetIdSidebySideItems}>
                                            <Select
                                                value=""
                                                onChange={() => {}}
                                                className={classes.dropdown}
                                                displayEmpty
                                                data-qa="attributeColor_ddl"
                                            >
                                                <MenuItem value="">
                                                    <em
                                                        className={classes.clrPickerLbl}
                                                        style={(attributeColor !== '') ? { background: `#${attributeColor}` } : undefined}
                                                        data-qa="attributeColor_pickedColors"
                                                    >
                                                        <span className={classes.clrPickerLblSpan}>
                                                            Label Color
                                                        </span>
                                                    </em>
                                                </MenuItem>
                                                <MenuItem value="fillColor" className={classes.clrPicker}>
                                                    <CompactPicker
                                                        onChangeComplete={value => this.handleColorChange('attributeColor', value)}
                                                        data-qa="attributeColor_colorPicker"
                                                    />
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid sx={{ paddingTop: '12px !Important' }} item>{this.renderClearButton('attributeColor')}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={7} style={c.inlined.ml20}>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item data-qa={`${c.deviceSerialFilter.property}_asyncDdlContainer`} className={extStyles.assetIdSidebySideItems}>
                                            {this.renderSelectBox(c.deviceSerialFilter, '250px')}
                                        </Grid>
                                        <Grid
                                            item
                                            className={extStyles.deviceSerialItem}
                                            style={{ display: 'flex' }}
                                        >
                                            <div
                                                className={showCopyContent ?
                                                    extStyles.contentCopyTitle :
                                                    extStyles.contentCopyDisable
                                                }
                                                data-qa="deviceSerialNumber_copyBtnContainer"
                                            >
                                                <Tooltip
                                                    title={showCopyContent ? contentCopyTitle || '' : ''}
                                                    placement="top-start"
                                                    onClose={() => setTimeout(() => {
                                                        this.setState({ contentCopyTitle: 'Copy' });
                                                    }, 100)}
                                                >
                                                    <ContentCopy
                                                        onClick={() =>
                                                            this.copyText(c.deviceSerialFilter)
                                                        }
                                                        color={showCopyContent ? 'primary' : 'disabled'}
                                                        data-qa="deviceSerialNumber_copyBtn"
                                                    />
                                                </Tooltip>
                                            </div>
                                            {this.renderClearButton('deviceSerialNumber')}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.bp8px}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} className={extStyles.gridPadding}>
                                    <Typography className={classes.cardTitle}>
                                        Asset Basics
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className={extStyles.gridPadding}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <TextField
                                                id="vin"
                                                label={this.state.ELDVisible === true ? 'VIN*' : 'VIN'}
                                                className={classes.input350px}
                                                value={this.state.vin}
                                                onChange={this.handleChange('vin')}
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(/[^a-z0-9]/gi, '').toString().toUpperCase().slice(0, 18);
                                                }}
                                                autoComplete="off"
                                                data-qa="vin_txtBox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="year"
                                                label="Year"
                                                className={classes.input350px}
                                                value={this.state.year}
                                                onChange={this.handleChange('year')}
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(
                                                        0,
                                                        parseInt(e.target.value, 10) || 0,
                                                    ).toString().slice(0, 4);
                                                }}
                                                data-qa="year_numBox"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={extStyles.gridPadding}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <TextField
                                                id="make"
                                                label="Make"
                                                className={classes.input350px}
                                                value={this.state.make}
                                                onChange={this.handleChange('make')}
                                                autoComplete="off"
                                                data-qa="make_txtBox"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                id="model"
                                                label="Model"
                                                className={classes.input350px}
                                                value={this.state.model}
                                                onChange={this.handleChange('model')}
                                                autoComplete="off"
                                                data-qa="model_txtBox"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {(this.showDashcam() || this.showLMDashcam()) &&
                            <Grid item xs={12} style={{ paddingTop: 40 }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} className={extStyles.gridPadding}>
                                        <Typography className={classes.cardTitle} sx={{ paddingLeft: '0px !Important' }}>
                                            CamCoach
                                        </Typography>
                                    </Grid>
                                    {this.showDashcam() && (
                                        <Grid item xs={12} className={extStyles.gridPadding}>
                                            <TextField
                                                id="camcoach-imei"
                                                label="Camcoach IMEI Number"
                                                className={classes.input86p}
                                                value={this.state.dashcamIdentifier}
                                                inputProps={{ maxLength: 75 }}
                                                onChange={this.handleChange('dashcamIdentifier')}
                                                autoComplete="off"
                                                data-qa="camcoach_imei_number_txtBox"
                                            />
                                        </Grid>
                                    )}
                                    {this.showLMDashcam() && (
                                        <Grid
                                            item
                                            xs={8}
                                            className={extStyles.lmDashcamGridPadding}
                                        >
                                            <div>
                                                <InputLabel
                                                    htmlFor={dashcamDevice.filterTitle}
                                                    disableAnimation
                                                    className={extStyles.lmDashcamLabel}
                                                >
                                                    {this.state.selectedDashcam ? dashcamDevice.filterTitle : ''}
                                                </InputLabel>
                                                <AsyncSelectWrapper
                                                    loadOptions={(inputValue, loadedOptions) =>
                                                        this.dashcamFilterOptions(
                                                            dashcamDevice,
                                                            inputValue,
                                                            loadedOptions,
                                                        )
                                                    }
                                                    onItemSelected={(selected) => {
                                                        this.setState({
                                                            selectedDashcam: selected,
                                                            showCamCoachCopyContent: true,
                                                            dashcamIdentifier: selected.data.id,
                                                        });
                                                    }}
                                                    selectedOptions={this.state.selectedDashcam}
                                                    title={dashcamDevice.filterTitle}
                                                    disableChips
                                                    showSelection
                                                    selectStyles={selectStyles}
                                                    inputProps={{ id: dashcamDevice.filterTitle }}
                                                    styles={{ container: { flex: 1, width: 327 } }}
                                                />
                                            </div>
                                            <div
                                                className={extStyles.camcoachItem}
                                                style={{ display: 'flex' }}
                                            >
                                                <div
                                                    className={showCamCoachCopyContent ?
                                                        extStyles.contentCopyTitle :
                                                        extStyles.contentCopyDisable
                                                    }
                                                    data-qa="camcoach_copyBtnContainer"
                                                >
                                                    <Tooltip
                                                        title={showCamCoachCopyContent ? contentCopyTitle || '' : ''}
                                                        placement="top-start"
                                                        onClose={() => setTimeout(() => {
                                                            this.setState({ contentCopyTitle: 'Copy' });
                                                        }, 100)}
                                                    >
                                                        <ContentCopy
                                                            onClick={() =>
                                                                this.copyText({ property: 'camCoachNumber' })
                                                            }
                                                            color={showCamCoachCopyContent ? 'primary' : 'disabled'}
                                                            data-qa="camcoachNumber_copyBtn"
                                                        />
                                                    </Tooltip>
                                                </div>
                                                {this.renderClearButton('selectedDashcam')}
                                            </div>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12} className={extStyles.gridItemPadding}>
                            <Typography className={classes.cardTitle} sx={{ padding: '20px 0px 0px !Important' }}>Tracking Details</Typography>
                            <Grid container spacing={2} alignItems="flex-start">
                                <Grid item xs={6} className={extStyles.odometerItem}>
                                    {
                                        this.trackingDistanceFields.showInitialHubometer &&
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item className={extStyles.initialodometerItem}>
                                                <TextField
                                                    id="initial-odometer"
                                                    label="Initial Odometer"
                                                    className={classes.input86p}
                                                    value={this.state.intialHubometer}
                                                    inputProps={{ min: 0, step: 'any' }}
                                                    onChange={this.handleChange('intialHubometer')}
                                                    type="number"
                                                    data-qa="initial_odometer_numBox"
                                                    disabled={this.isInitOdoAndEngineHoursDisabled(
                                                        this.initialDataObjForRetrieval
                                                            .intialOdometer)}
                                                />
                                            </Grid>
                                            <Grid item data-qa="odometer_unit">{this.distanceString.long}</Grid>
                                            <div className={extStyles.messageText}>
                                                {this.renderHelpBtn('initialOdometer')}
                                            </div>
                                        </Grid>
                                    }
                                    {
                                        this.trackingDistanceFields.showGPSTrackedDistance &&
                                        <Grid container spacing={1} alignItems="flex-start" className={extStyles.mt40px}>
                                            <Grid item className={classes.trackingDetailTag} data-qa="gpsTrackedDistance_label">
                                                GPS Tracked {this.distanceString.long}
                                            </Grid>
                                            <Grid item data-qa="gpsTrackedDistance">
                                                {getCorrectDistance(this.state.gpsTrackedDistance / 1000, 'km').toFixed(1)}
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        this.trackingDistanceFields.showEstTotalDistance &&
                                        <Grid container spacing={1} alignItems="flex-start" className={extStyles.mt40px}>
                                            <Grid item className={classes.trackingDetailTag} data-qa="estTotalDistance_label">
                                                EST. Total {this.distanceString.long}
                                                {this.renderHelpBtn('miles')}
                                            </Grid>
                                            <Grid item data-qa="estTotalDistance">{this.renderEstTotalData('miles')}</Grid>
                                        </Grid>
                                    }
                                    {
                                        this.trackingDistanceFields.showAssetDistanceSum &&
                                        <Grid container spacing={1} alignItems="flex-start" className={this.trackingDistanceFields.showOnlyAssetDistanceSum ? undefined : extStyles.mt40px}>
                                            <Grid item className={classes.trackingDetailTag} data-qa="assetDistanceSum_label">
                                                Odometer ({this.distanceString.short})
                                            </Grid>
                                            <Grid item data-qa="assetDistanceSum">
                                                {getCorrectDistance(this.state.assetDistanceSum / 1000, 'km').toFixed(1)}
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item xs={6} className={extStyles.gridPadding}>
                                    {
                                        this.trackingEngineHoursFields.showInitialEngineHours &&
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item>
                                                <TextField
                                                    id="initial-engine-hours"
                                                    label="Initial Engine Hours"
                                                    className={classes.input86p}
                                                    value={initialEngineTime}
                                                    inputProps={{ min: 0, step: 'any' }}
                                                    onChange={this.handleChange('initialEngineTime')}
                                                    type="number"
                                                    data-qa="initialEngineTime_numBox"
                                                    disabled={this.isInitOdoAndEngineHoursDisabled(
                                                        this.initialDataObjForRetrieval
                                                            .initialEngineTime)}
                                                />
                                            </Grid>
                                            <Grid item data-qa="engineTime_unit">Hours</Grid>
                                            <div className={extStyles.messageText}>
                                                {this.renderHelpBtn('initialEngineTime')}
                                            </div>
                                        </Grid>
                                    }
                                    {
                                        this.trackingEngineHoursFields.showDeviceTrackedEngHours &&
                                        <Grid container spacing={1} alignItems="flex-start" className={extStyles.mt40px}>
                                            <Grid item className={classes.trackingDetailTag} data-qa="stateTrackedEngineTime_label">
                                                Device Tracked Engine Hours
                                            </Grid>
                                            <Grid item data-qa="stateTrackedEngineTime">
                                                {parseFloat(this.state.stateTrackedEngineTime || 0)
                                                    .toFixed(1)}
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        this.trackingEngineHoursFields.showEstTotalHours &&
                                        <Grid container spacing={1} alignItems="flex-start" className={extStyles.mt19px}>
                                            <Grid item className={classes.trackingDetailTag} data-qa="estTotalHours_label">
                                                EST. Total Hours
                                                {this.renderHelpBtn('hours')}
                                            </Grid>
                                            <Grid item data-qa="estTotalHours">{this.renderEstTotalData('hours')}</Grid>
                                        </Grid>
                                    }
                                    {
                                        this.trackingEngineHoursFields.showTrueEngineHours &&
                                        <Grid container spacing={1} alignItems="flex-start" className={extStyles.mt19px}>
                                            <Grid item className={classes.trackingDetailTag} data-qa="trueEngineHours_label">
                                                Total Engine Hours
                                            </Grid>
                                            <Grid item data-qa="trueEngineHours">{this.renderEstTotalData('hours')}</Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={extStyles.gridItemPadding}>
                            <Typography sx={{ padding: '14px 0px 0px !Important' }} className={classes.cardTitle}>Notes</Typography>
                            <Grid item>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="notes"
                                            label="Notes"
                                            multiline
                                            className={classes.noteTextArea}
                                            value={attributeNotes}
                                            inputProps={{ maxLength: 4000 }}
                                            onChange={this.handleChange('attributeNotes')}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }

    renderUpdatesCard = classes => (
        <div ref={this.updatesRef} data-qa="update_section">
            <Paper className={classes.blankCard}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.cardTitle}>Updates</Typography>
                    </Grid>
                    { this.trackingDistanceFields.showInitialHubometer &&
                    <Grid item xs={6}>
                        <Typography className={classes.cardSubTitle} data-qa="updateOdometer_label">Update
                            Odometer
                        </Typography>
                        <Grid item style={c.inlined.recOdoGrid}>
                            <Grid container alignItems="flex-start" style={c.inlined.recOdoContainer}>
                                <Grid item data-qa="odometerAdjustmentVal_label">Recorded Odometer</Grid>
                                <Grid item>
                                    <TextField
                                        id="recorded-odometer"
                                        placeholder="0"
                                        type="number"
                                        className={classes.input100px}
                                        value={this.state.odometerAdjustmentVal}
                                        inputProps={{ min: 0, step: 'any' }}
                                        onChange={this.handleChange('odometerAdjustmentVal')}
                                        style={c.inlined.m020}
                                        data-qa="odometerAdjustmentVal_numBox"
                                    />
                                </Grid>
                                <Grid item data-qa="odometerAdjustmentVal_unit">{this.distanceString.long}</Grid>
                            </Grid>
                            <Grid container alignItems="center" className={extStyles.dateContainer}>
                                <Grid item data-qa="odometerAdjustmentDate_label">Date Recorded</Grid>
                                <Grid item>
                                    <div className={classes.dateContainer}>
                                        <LocalizationProvider dateAdapter={MomentUtils}>
                                            <MobileDatePicker
                                                placeholder="mm/dd/yyyy"
                                                emptyLabel="mm/dd/yyyy"
                                                invalidLabel="mm/dd/yyyy"
                                                format="MM/DD/YYYY"
                                                ToolbarComponent={() => (
                                                    <Toolbar
                                                        className={extStyles.toolbar}
                                                    >{moment(this.state.odometerPickedDate).format('ddd, MMM D').toString()}
                                                    </Toolbar>
                                                )}
                                                sx={{ '& .MuiPickersToolbar-penIconButton': { display: 'none' } }}
                                                open={this.state.odometerDatePickerOpen}
                                                renderInput={params =>
                                                    <TextField
                                                        onClick={this.odometerCalendarOpenHandler}
                                                        {...params}
                                                        placeholder="mm/dd/yyyy"
                                                        sx={{ '& .Mui-error:after': { borderBottom: 'unset' } }}
                                                    />
                                                }
                                                components={{
                                                    ActionBar: () => (
                                                        <DialogActions>
                                                            <Button
                                                                color="primary"
                                                                onClick={() => {
                                                                    this.calendarCloseHandler('odometer')
                                                                }}
                                                            >Cancel
                                                            </Button>
                                                            <Button
                                                                color="primary"
                                                                onClick={() => {
                                                                    this.handleDateChange('odometer',
                                                                        this
                                                                            .state
                                                                            .odometerPickedDate,
                                                                    );
                                                                }}
                                                            >
                                                                Ok
                                                            </Button>
                                                        </DialogActions>
                                                    ),
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={this
                                                                .odometerCalendarOpenHandler}
                                                            >
                                                                <Calendar/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                DialogProps={{
                                                    onBackdropClick: () => {
                                                        this.setState({
                                                            odometerDatePickerOpen: false,
                                                        })
                                                    },
                                                }}
                                                id="update-odometer-recorded-date"
                                                value={this.state.odometerAdjustmentDate}
                                                onChange={(date) => {
                                                    this.setState({ odometerPickedDate: date });
                                                }}
                                                renderDay={this.odometerRenderDays}
                                                disableFuture
                                                className={classes.dateField}
                                                showToolbar
                                                toolbarTitle=""
                                                mask="__/__/____"
                                                data-qa="odometerAdjustmentDate_datePicker"
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                    { this.trackingDistanceFields.showInitialHubometer &&
                    <Grid item xs={6} data-qa="odometerAdjustmentHistory_container">
                        <Typography className={classes.cardSubTitle}>
                            Odometer Change History
                        </Typography>
                        <Grid item className={classes.updatesLabel}>
                            Date <span style={c.inlined.m032}>-</span> Mileage Update
                        </Grid>
                        {this.state.odometerAdjustmentHistory.map((history, i) => {
                            const key = history + i;
                            return (
                                <Grid
                                    item
                                    key={key}
                                    className={classes.updatesValue}
                                    data-qa={`odometerAdjustmentHistory_${i}`}
                                >
                                    {moment(history.split('-')[0], 'M/D/YY').format('MM/DD/YYYY')}
                                    <span style={c.inlined.historyRow}>-</span>
                                    {history.split('-')[1]}{this.distanceString.short}
                                </Grid>
                            );
                        })}
                    </Grid>
                    }
                    { this.trackingEngineHoursFields.showInitialEngineHours
                        && this.trackingDistanceFields.showInitialHubometer &&
                    <Grid item xs={12}><Divider /></Grid> }
                    { this.trackingEngineHoursFields.showInitialEngineHours &&
                    <Grid item xs={6}>
                        <Typography className={classes.cardSubTitle}>
                            Update Engine Hours
                        </Typography>
                        <Grid item style={c.inlined.engHrs}>
                            <Grid container alignItems="flex-start" style={c.inlined.mb10}>
                                <Grid item>Current Time</Grid>
                                <Grid item>
                                    <TextField
                                        id="current-time"
                                        placeholder="0"
                                        type="number"
                                        className={classes.input100px}
                                        inputProps={{ min: 0, step: 'any' }}
                                        value={this.state.engineHourAdjustmentVal}
                                        onChange={this.handleChange('engineHourAdjustmentVal')}
                                        style={c.inlined.m020}
                                        data-qa="engineHourAdjustmentVal_numBox"
                                    />
                                </Grid>
                                <Grid item data-qa="engineHourAdjustmentVal_unit">Hours</Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" className={extStyles.dateContainer}>
                                <Grid item>Date Recorded</Grid>
                                <Grid item>
                                    <div className={classes.dateContainer}>
                                        <LocalizationProvider dateAdapter={MomentUtils}>
                                            <MobileDatePicker
                                                ToolbarComponent={() => (
                                                    <Toolbar
                                                        className={extStyles.toolbar}
                                                    >{moment(this.state.engineHourPickedDate).format('ddd, MMM D').toString()}
                                                    </Toolbar>
                                                )}
                                                sx={{ '& .MuiPickersToolbar-penIconButton': { display: 'none' } }}
                                                open={this.state.engineHourDatePickerOpen}
                                                id="update-engine-hours-recorded-date"
                                                renderInput={params =>
                                                    <TextField
                                                        onClick={this.engineHourCalendarOpenHandler}
                                                        {...params}
                                                        placeholder="mm/dd/yyyy"
                                                        sx={{ '& .Mui-error:after': { borderBottom: 'unset' } }}
                                                    />
                                                }
                                                components={{
                                                    ActionBar: () => (
                                                        <DialogActions>
                                                            <Button
                                                                color="primary"
                                                                onClick={() => {
                                                                    this.calendarCloseHandler('engineHour')
                                                                }}
                                                            >Cancel
                                                            </Button>
                                                            <Button
                                                                color="primary"
                                                                onClick={() => {
                                                                    this.handleDateChange('engineHour',
                                                                        this
                                                                            .state
                                                                            .engineHourPickedDate,
                                                                    );
                                                                }}
                                                            >
                                                                Ok
                                                            </Button>
                                                        </DialogActions>
                                                    ),
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={this
                                                                .engineHourCalendarOpenHandler}
                                                            >
                                                                <Calendar/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                DialogProps={{
                                                    onBackdropClick: () => {
                                                        this.setState({
                                                            engineHourDatePickerOpen: false,
                                                        })
                                                    },
                                                }}
                                                value={this.state.engineHourAdjustmentDate}
                                                onChange={(date) => {
                                                    this.setState({ engineHourPickedDate: date });
                                                }}
                                                format="MM/DD/YYYY"
                                                disableFuture
                                                className={classes.dateField}
                                                showToolbar
                                                toolbarTitle=""
                                                mask="__/__/____"
                                                data-qa="engineHourAdjustmentDate_datePicker"
                                                renderDay={this.engineHourRenderDays}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                    { this.trackingEngineHoursFields.showInitialEngineHours &&
                    <Grid item xs={6} data-qa="engineTimeAdjustmentHistory_container">
                        <Typography className={classes.cardSubTitle}>
                            Engine Hours Change History
                        </Typography>
                        <Grid item className={classes.updatesLabel}>
                            Date <span style={c.inlined.m032}>-</span> Engine Hours Update
                        </Grid>
                        {this.state.engineTimeAdjustmentHistory.map((history, i) => {
                            const key = history + i;
                            return (
                                <Grid
                                    item
                                    key={key}
                                    className={classes.updatesValue}
                                    data-qa={`engineTimeAdjustmentHistory_${i}`}
                                >
                                    {moment(history.split('-')[0], 'M/D/YY').format('MM/DD/YYYY')}
                                    <span style={c.inlined.historyRow}>-</span>
                                    {history.split('-')[1]}
                                </Grid>
                            );
                        })}
                    </Grid>
                    }
                </Grid>
            </Paper>
        </div>
    )

    renderAssociationsCard = classes => (
        <div ref={this.associationsRef} data-qa="associations_section">
            <Paper className={classes.blankCard}>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.bp8px}>
                        <Typography className={classes.cardTitle}>Associations</Typography>
                    </Grid>
                    <Grid item xs={12} className={extStyles.tbZeroPadding}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item className={extStyles.associationsDropdown} data-qa={`${c.assetGroupFilter().property}_asyncDdlContainer`}>
                                {this.renderSelectBox(c.assetGroupFilter(), '330px')}
                            </Grid>
                            <Grid item className={extStyles.selectBoxClearBtnGrid}>
                                {this.renderClearButton('groupId')}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={extStyles.tbZeroPadding}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item className={extStyles.associationsDropdown} data-qa={`${c.driversFilter.property}_asyncDdlContainer`}>
                                {this.renderSelectBox(c.driversFilter, '330px')}
                            </Grid>
                            <Grid item className={extStyles.selectBoxClearBtnGrid}>
                                {this.renderClearButton('operatorId')}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={extStyles.tbZeroPadding}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item className={extStyles.associationsDropdown} data-qa={`${c.tagsFilter.property}_asyncDdlContainer`}>
                                {this.renderSelectBox(c.tagsFilter, '330px')}
                            </Grid>
                            <Grid item sx={{ padding: '44px 12px 0px !Important' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.clearButton}
                                    onClick={() => this.addChip()}
                                    data-qa={`${c.tagsFilter.property}_addBtn`}
                                >
                                Add
                                </Button>
                            </Grid>
                            <Grid item className={extStyles.chipContainer} data-qa={`${c.tagsFilter.property}_chipContainer`}>
                                {
                                    this.state.chipData.map((d, i) => {
                                        const key = d.key + i.toString();
                                        return (
                                            <Chip
                                                id={d.key}
                                                key={key}
                                                label={d.label}
                                                onDelete={this.handleChipDelete(d)}
                                                style={c.inlined.m0206}
                                                data-qa={`${c.tagsFilter.property}_chip_${i}`}
                                                sx={{ backgroundColor: '#e0e0e0' }}
                                            />
                                        );
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )

    getMenuData = (menuItems, inputIndex) => {
        const type = this.state[`input${inputIndex}Type`];
        if (type === 'system') {
            return [...menuItems, ...c.systemDefaultInputs];
        }
        return menuItems;
    }

    isInputStateMenuDisabled = (inputIndex) => !this.state[`input${inputIndex}HighName`] || (this.state[`input${inputIndex}Type`] === 'system' && this.state[`input${inputIndex}HighStateName`]);

    renderInputsCard = classes => (
        <div ref={this.inputsRef} data-qa="input_section">
            <Paper className={classes.blankCard}>
                <Grid container spacing={2}>
                    <Grid item className={classes.bp8px}>
                        <Typography className={classes.cardTitle}>Inputs</Typography>
                    </Grid>
                    <Grid container className={extStyles.inputGrid}>
                        <Grid item xs={6} className={classes.inputItem}>
                            <Typography className={classes.cardSubTitle}>Input 1</Typography>
                            <Grid item className={extStyles.inputsSBoxGrid}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item className={extStyles.inputNameGrid} data-qa={`${c.inputsFilter1.property}_asyncDdlContainer`}>
                                        {this.renderSelectBox(c.inputsFilter1, '390px')}
                                    </Grid>
                                    <Grid item className={extStyles.InputBoxBtnGrid}>
                                        {this.renderClearButton('inputsFilter1')}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} alignItems="center" className={extStyles.inputsSelectGrid}>
                                <Grid item className={extStyles.gridItemPadding}>
                                    {this.renderSelect(
                                        'low-state-1',
                                        '[Low State]',
                                        this.handleInputChange('input1LowStateName'),
                                        this.state.input1LowStateName,
                                        this.getMenuData(c.defaultInputs, 1),
                                        classes.cardDrop,
                                        this.isInputStateMenuDisabled(1),
                                    )}
                                </Grid>
                                <Grid item className={extStyles.gridItemPadding}>
                                    {this.renderSelect(
                                        'high-state-1',
                                        '[High State]',
                                        this.handleInputChange('input1HighStateName'),
                                        this.state.input1HighStateName,
                                        this.getMenuData(c.defaultInputs, 1),
                                        classes.cardDrop,
                                        this.isInputStateMenuDisabled(1),
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className={classes.inputItem}>
                            <Typography className={classes.cardSubTitle}>Input 2</Typography>
                            <Grid item className={extStyles.inputsSBoxGrid}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item className={extStyles.inputNameGrid} data-qa={`${c.inputsFilter2.property}_asyncDdlContainer`}>
                                        {this.renderSelectBox(c.inputsFilter2, '390px')}
                                    </Grid>
                                    <Grid item className={extStyles.InputBoxBtnGrid}>
                                        {this.renderClearButton('inputsFilter2')}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} alignItems="center" className={extStyles.inputsSelectGrid}>
                                <Grid item className={extStyles.gridItemPadding}>
                                    {this.renderSelect(
                                        'low-state-2',
                                        '[Low State]',
                                        this.handleInputChange('input2LowStateName'),
                                        this.state.input2LowStateName,
                                        this.getMenuData(c.defaultInputs, 2),
                                        classes.cardDrop,
                                        this.isInputStateMenuDisabled(2),
                                    )}
                                </Grid>
                                <Grid item className={extStyles.gridItemPadding}>
                                    {this.renderSelect(
                                        'high-state-2',
                                        '[High State]',
                                        this.handleInputChange('input2HighStateName'),
                                        this.state.input2HighStateName,
                                        this.getMenuData(c.defaultInputs, 2),
                                        classes.cardDrop,
                                        this.isInputStateMenuDisabled(2),
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={extStyles.inputGrid}>
                        <Grid item xs={6} className={classes.inputItem}>
                            <Typography className={classes.cardSubTitle}>Input 3</Typography>
                            <Grid item className={extStyles.inputsSBoxGrid}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item className={extStyles.inputNameGrid} data-qa={`${c.inputsFilter3.property}_asyncDdlContainer`}>
                                        {this.renderSelectBox(c.inputsFilter3, '390px')}
                                    </Grid>
                                    <Grid item className={extStyles.InputBoxBtnGrid}>
                                        {this.renderClearButton('inputsFilter3')}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} alignItems="center" className={extStyles.inputsSelectGrid}>
                                <Grid item className={extStyles.gridItemPadding}>
                                    {this.renderSelect(
                                        'low-state-3',
                                        '[Low State]',
                                        this.handleInputChange('input3LowStateName'),
                                        this.state.input3LowStateName,
                                        this.getMenuData(c.defaultInputs, 3),
                                        classes.cardDrop,
                                        this.isInputStateMenuDisabled(3),
                                    )}
                                </Grid>
                                <Grid item className={extStyles.gridItemPadding}>
                                    {this.renderSelect(
                                        'high-state-3',
                                        '[High State]',
                                        this.handleInputChange('input3HighStateName'),
                                        this.state.input3HighStateName,
                                        this.getMenuData(c.defaultInputs, 3),
                                        classes.cardDrop,
                                        this.isInputStateMenuDisabled(3),
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className={classes.inputItem}>
                            <Typography className={classes.cardSubTitle}>Input 4</Typography>
                            <Grid item className={extStyles.inputsSBoxGrid}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item className={extStyles.inputNameGrid} data-qa={`${c.inputsFilter4.property}_asyncDdlContainer`}>
                                        {this.renderSelectBox(c.inputsFilter4, '390px')}
                                    </Grid>
                                    <Grid item className={extStyles.InputBoxBtnGrid}>
                                        {this.renderClearButton('inputsFilter4')}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} alignItems="center" className={extStyles.inputsSelectGrid}>
                                <Grid item className={extStyles.gridItemPadding}>
                                    {this.renderSelect(
                                        'low-state-4',
                                        '[Low State]',
                                        this.handleInputChange('input4LowStateName'),
                                        this.state.input4LowStateName,
                                        this.getMenuData(c.defaultInputs, 4),
                                        classes.cardDrop,
                                        this.isInputStateMenuDisabled(4),
                                    )}
                                </Grid>
                                <Grid item className={extStyles.gridItemPadding}>
                                    {this.renderSelect(
                                        'high-state-4',
                                        '[High State]',
                                        this.handleInputChange('input4HighStateName'),
                                        this.state.input4HighStateName,
                                        this.getMenuData(c.defaultInputs, 4),
                                        classes.cardDrop,
                                        this.isInputStateMenuDisabled(4),
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )

    renderFuelCard = (classes) => {
        let accountNumFltr = { ...c.accountNumFilter };
        let fuelCardFltr = { ...c.fuelCardFilter };

        if (hasFeature('fleetWexFuelCard')) {
            accountNumFltr = { ...c.wexAccountNumFilter };
            fuelCardFltr = { ...c.wexFuelCardFilter };
        } else if (hasFeature('fleetFleetCorFuelCard')) {
            accountNumFltr = { ...c.fleetCorAccountNumFilter };
            fuelCardFltr = { ...c.fleetCorFuelCardFilter };
        }

        return (
            <div ref={this.fuelCardRef} data-qa="fuel_section">
                <Paper className={classes.blankCard}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography className={classes.cardTitle}>Fuel Card</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.lp30px} style={c.inlined.pb0}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item sx={{ paddingLeft: '36px !Important' }}>
                                    {this.renderSelect(
                                        'fuel-type',
                                        'Fuel Type',
                                        this.handleChange('attributeFuelType'),
                                        this.state.attributeFuelType,
                                        this.state.fuelTypes,
                                        classes.cardDrop,
                                    )}
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="flex-start" spacing={2}>
                                        <Grid item data-qa="attributeCapacity_label">Fuel Tank Capacity</Grid>
                                        <Grid item>
                                            <TextField
                                                id="tank-size"
                                                className={classes.input100px}
                                                onChange={this.handleChange('attributeCapacity')}
                                                type="number"
                                                inputProps={{ min: 1, max: 200 }}
                                                value={this.state.attributeCapacity}
                                                data-qa="attributeCapacity_numBox"
                                            />
                                        </Grid>
                                        <Grid item data-qa="attributeCapacity_unit">(gal)</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={extStyles.tbZeroPadding}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item className={extStyles.accountDropdown} data-qa={`${accountNumFltr.property}_asyncDdlContainer`}>
                                    { this.renderSelectBox(accountNumFltr, '330px') }
                                </Grid>
                                <Grid item className={extStyles.selectBoxClearBtnGrid}>
                                    {this.renderClearButton('fuelCardAccountId')}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={extStyles.tbZeroPadding}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item className={extStyles.fuelCardDropdown} data-qa={`${fuelCardFltr.property}_asyncDdlContainer`}>
                                    {this.renderSelectBox(fuelCardFltr, '330px', this.state.fuelCardAccountId)}
                                </Grid>
                                <Grid item className={extStyles.selectBoxClearBtnGrid}>
                                    {this.renderClearButton('fuelCardId')}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }

    renderELDCard = classes => (
        <div ref={this.complianceRef} data-qa="compliance_section">
            <Paper className={classes.blankCard}>
                <Typography className={classes.cardTitle}>Compliance</Typography>
                <ul style={c.inlined.eldCardUL} onChange={e => this.selectELD(e.target)}>
                    {this.renderRadio('required', 'Required')}
                    {this.renderRadio('not-required', 'Not Required')}
                </ul>
            </Paper>
        </div>
    )

    selectELD = e => this.setState({ eldRequired: (e.id === 'required') });

    renderSafetyCard = classes => (
        <div ref={this.safetyRef} data-qa="safety_section">
            <Paper className={classes.blankCard}>
                <Typography className={classes.cardTitle}>Safety Profile</Typography>
                <Table style={c.inlined.safetyCardTbl}>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.tableCell}>
                                <div className={this.isFl360ConfigDisabled() ? '' : this.hideFL360OnOffCheckBox()}>
                                    <Typography
                                        className={classes.cardSubTitle}
                                        style={c.inlined.headerCell}
                                    >
                                    On/Off
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Typography
                                    className={classes.cardSubTitle}
                                    style={c.inlined.headerCell}
                                >
                                Warning Buzzer
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.tableCell}>
                                <Grid container alignItems="center">
                                    <div className={extStyles.safetyGridAlignment}>
                                        <Grid item data-qa="highSpeed_label">High Speed ({getSpeedUnitString()})</Grid>
                                    </div>
                                </Grid>
                                <Grid container alignItems="flex-start">
                                    <Grid
                                        item
                                        className={this.handleSafetyProfileLayout()}
                                    >
                                        <Checkbox
                                            checked={this.state.highSpeedChecked}
                                            onChange={this.handleCheckChange('highSpeedChecked')}
                                            value="highSpeedChecked"
                                            color="primary"
                                            className={extStyles.safetyTblChkBox}
                                            data-qa="highSpeed_cBox"
                                            disabled={this.isFl360ConfigDisabled()}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="highSpeed"
                                            onChange={this.handleChange('highSpeed')}
                                            value={this.state.highSpeed !== '' ? this.state.highSpeed : '0'}
                                            className={classes.input100px}
                                            style={c.inlined.mt_6}
                                            autoComplete="off"
                                            data-qa="highSpeed_numBox"
                                            disabled={this.handleEnableDisableForText(this.state
                                                .highSpeedChecked)}
                                            inputProps={{
                                                className: this.handleSafetyTextInputProps(),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <div className={this.handleSafetyProfileLayoutForBuzzers()}>
                                    <Checkbox
                                        checked={this.state.wb1Checked}
                                        onChange={this.handleCheckChange('wb1Checked')}
                                        value="wb1Checked"
                                        color="primary"
                                        className={extStyles.safetyTblChkBox}
                                        data-qa="highSpeed_warBtn"
                                        disabled={this.handleEnableDisableForBuzzers(this.state
                                            .highSpeedChecked)}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.tableCell}>
                                <Grid container alignItems="center">
                                    <div className={extStyles.safetyGridAlignment}>
                                        <Grid item data-qa="highRPM_label">RPM</Grid>
                                    </div>
                                </Grid>
                                <Grid container alignItems="flex-start">
                                    <Grid
                                        item
                                        className={this.handleSafetyProfileLayout()}
                                    >
                                        <Checkbox
                                            checked={this.state.highRPMChecked}
                                            onChange={this.handleCheckChange('highRPMChecked')}
                                            value="highRPMChecked"
                                            color="primary"
                                            className={extStyles.safetyTblChkBox}
                                            data-qa="highRPM_cBox"
                                            disabled={this.isFl360ConfigDisabled()}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id="highSpeed"
                                            onChange={this.handleChange('highRPM')}
                                            value={this.state.highRPM !== '' ? this.state.highRPM : '0'}
                                            className={classes.input100px}
                                            style={c.inlined.mt_6}
                                            autoComplete="off"
                                            data-qa="highRPM_numBox"
                                            disabled={this.handleEnableDisableForText(this.state
                                                .highRPMChecked)}
                                            inputProps={{
                                                className: this.handleSafetyTextInputProps(),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <div className={this.handleSafetyProfileLayoutForBuzzers()}>
                                    <Checkbox
                                        checked={this.state.wb2Checked}
                                        onChange={this.handleCheckChange('wb2Checked')}
                                        value="wb2Checked"
                                        color="primary"
                                        className={extStyles.safetyTblChkBox}
                                        data-qa="highRPM_warBtn"
                                        disabled={this.handleEnableDisableForBuzzers(this.state
                                            .highRPMChecked)}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.tableCell}>
                                <Grid container alignItems="flex-start">
                                    <Grid
                                        item
                                        className={this.handleSafetyProfileLayout()}
                                    >
                                        <Checkbox
                                            checked={!!this.state.hardBrakingChecked}
                                            onChange={this.handleCheckChange('hardBrakingChecked')}
                                            value="hardBrakingChecked"
                                            color="primary"
                                            className={extStyles.safetyTblChkBox}
                                            data-qa="hardBraking_cBox"
                                            disabled={this.isFl360ConfigDisabled()}
                                        />
                                    </Grid>
                                    <Grid item data-qa="hardBraking_label">Hard Braking</Grid>
                                </Grid>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <div className={this.handleSafetyProfileLayoutForBuzzers()}>
                                    <Checkbox
                                        checked={this.state.wb3Checked}
                                        onChange={this.handleCheckChange('wb3Checked')}
                                        value="wb3Checked"
                                        color="primary"
                                        className={extStyles.safetyTblChkBox}
                                        data-qa="hardBraking_warBtn"
                                        disabled={this.handleEnableDisableForBuzzers(this.state
                                            .hardBrakingChecked)}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.tableCell}>
                                <Grid container alignItems="flex-start">
                                    <Grid
                                        item
                                        className={this.handleSafetyProfileLayout()}
                                    >
                                        <Checkbox
                                            checked={!!this.state.hardAccelerationChecked}
                                            onChange={this.handleCheckChange('hardAccelerationChecked')}
                                            value="hardAccelerationChecked"
                                            color="primary"
                                            className={extStyles.safetyTblChkBox}
                                            data-qa="hardAcceleration_cBox"
                                            disabled={this.isFl360ConfigDisabled()}
                                        />
                                    </Grid>
                                    <Grid item data-qa="hardAcceleration_label">Hard Acceleration</Grid>
                                </Grid>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <div className={this.handleSafetyProfileLayoutForBuzzers()}>
                                    <Checkbox
                                        checked={this.state.wb4Checked}
                                        onChange={this.handleCheckChange('wb4Checked')}
                                        value="wb4Checked"
                                        color="primary"
                                        className={extStyles.safetyTblChkBox}
                                        data-qa="hardAcceleration_warBtn"
                                        disabled={this.handleEnableDisableForBuzzers(this.state
                                            .hardAccelerationChecked)}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                {this.isFl360ConfigDisabled() ? <SafetyProfileDownMessage /> : ''}
            </Paper>
        </div>
    );

    cursorNotAllowedStyleForFL360 = () => (this.isFl360() ? extStyles.cursorPointerNotAllowed : '');

    fetchSafetyProfileTextValue = (value, defaultValue) => ((value === null || value === undefined || value === '') ? defaultValue : value);

    hideFL360OnOffCheckBox = () => (this.isFl360() ? extStyles.safetyTblHideCheckBox : '');

    enableSafetyBuzzersForFL360 = checkedValue => (!this.isFl360() ? !checkedValue : false);

    isFl360ConfigDisabled = () => (this.isFl360() && !this.state.sendFL360Commands)

    handleSafetyProfileLayout = () => (this.isFl360ConfigDisabled() ?
        this.cursorNotAllowedStyleForFL360() : this.hideFL360OnOffCheckBox())

    handleSafetyProfileLayoutForBuzzers = () => (this.isFl360() &&
    !this.state.sendFL360Commands ? `${this.cursorNotAllowedStyleForFL360()} ${extStyles.safetyCheckBoxWidth}` : '')

    handleEnableDisableForBuzzers = value => (!this.state.sendFL360Commands ?
        (this.isFl360() || !value) : this.enableSafetyBuzzersForFL360(value))

    handleEnableDisableForText = value => (this.isFl360ConfigDisabled() ?
        true : this.enableSafetyBuzzersForFL360(value))

    handleSafetyTextInputProps = () => (!this.state.sendFL360Commands ? this.cursorNotAllowedStyleForFL360() : '')

    renderMaintenanceCard = classes => (
        <div ref={this.maintenance} data-qa="maintenance_section">
            <MaintenanceCard
                classes={classes}
                assetId={this.state.globalId || this.state.id}
            />
        </div>
    );

    installationSectionLabel = (key) => {
        let label = '';
        switch (key) {
        case 'date':
            label = 'Date';
            break;
        case 'GPSLocation':
            label = 'GPS Location';
            break;
        case 'deviceSerial':
            label = 'Device Serial';
            break;
        case 'cellularTest':
            label = 'Cellular Test';
            break;
        case 'GPSTest':
            label = 'GPS Test';
            break;
        case 'ignitionTest':
            label = 'Ignition Test';
            break;
        case 'driverIDTest':
            label = 'Driver ID Test';
            break;
        case 'starterTest':
            label = 'Starter Enable/Disable Test';
            break;
        case 'batteryTest':
            label = 'Battery Test';
            break;
        case 'batteryPercentage':
            label = 'Battery Percentage';
            break;
        case 'installationType':
            label = 'Installation Type';
            break;
        case 'installedLocation':
            label = 'Installed Location';
            break;
        case 'installedDevices':
            label = 'Additional Devices';
            break;
        case 'installerNotes':
            label = 'Installer Notes';
            break;
        default: break;
        }
        return label;
    }

    getTestResultValue(a) {
        let val = '';
        if (['cellularTest', 'GPSTest', 'ignitionTest', 'driverIDTest', 'starterTest', 'batteryTest'].indexOf(a) >= 0) {
            if (this.state.installationSection[a].toLowerCase() === 'passed') {
                val = extStyles.passed;
            } else if (this.state.installationSection[a] === '--') {
                val = extStyles.unknown;
            } else val = '';
        }
        return val;
    }

    getHelpText(type) {
        switch (type) {
        case 'miles':
            return (
                <span data-qa={`${type}_estTotalHelpPopoverContent`}>
                    Initial Odometer<br />
                    + GPS Tracked<br />
                    + Calculated Offset<br />
                    --------------------------------<br />
                    Estimated Total
                </span>
            );
        case 'hours':
            return (
                <span data-qa={`${type}_estTotalHelpPopoverContent`}>
                    Initial Engine Hours<br />
                    + Device Tracked Hours<br />
                    + Calculated Offset<br />
                    --------------------------------<br />
                    Estimated Total
                </span>
            );
        case 'initialOdometer':
            return (
                <span data-qa={`${type}_estTotalHelpPopoverContent`}>
                    Initial odometer can only be set once for an asset.<br />
                    If odometer needs to be adjusted in the future,<br />
                    please use the &quot;Odometer Update&quot; section below.
                </span>
            );
        case 'initialEngineTime':
            return (
                <span data-qa={`${type}_estTotalHelpPopoverContent`}>
                    Initial engine hours can only be set once for an asset. <br />
                    If engine hours needs to be adjusted in the future,<br />
                    please use the &quot;Engine Hour Update&quot; section below.
                </span>
            );
        default:
            return (
                <span data-qa={`${type}_estTotalHelpPopoverContent`}>
                    When moving a device to a new asset, we
                    recommend creating a new asset<br />to maintain
                    accurate odometer records. If you use the
                    same asset and just<br />edit the vehicle details,
                    the old mileage history will be used, leading to
                    <br />incorrect mileage reporting for the new
                    vehicle. For a seamless transition<br />and reliable
                    data, always create a new asset when moving devices
                    between vehicles.
                </span>
            );
        }
    }

    renderInstallationCard = (classes) => {
        if (!this.state.installationVisible) {
            return false;
        }
        const keys = Object.keys(this.state.installationSection);
        return (
            <div ref={this.installationRef} data-qa="installation_section">
                <Paper className={classes.blankCard}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className={classes.installationCardTitle}>
                                Installation
                            </Typography>
                        </Grid>
                        <Grid style={{ marginTop: 8, marginRight: 16 }}>
                            {
                                keys.map(a => (
                                    <Typography className={classes.installationUIValues} key={a}>
                                        {this.installationSectionLabel(a)}:
                                    </Typography>
                                ))
                            }
                        </Grid>
                        <Grid item xs={5} md={7}>
                            {
                                keys.map(a => (
                                    <Typography
                                        className={`${extStyles[a]} ${this.getTestResultValue(a)}`}
                                        key={a}
                                    >
                                        {this.state.installationSection[a]}
                                    </Typography>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }

    getType = () => {
        let type = '';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.closePostDialogBox(),
        }];
        if (this.state.validationDialogBox.isError) {
            type = 'error';
        }
        return type;
    }

    closePostDialogBox = () => {
        this.setState({
            validationDialogBox: {
                isError: false,
                header: '',
                msg: '',
            },
        }, () => {
            this.props.history.goBack();
        });
    }

    validateInitOdoAndEngineHours = value => !(value === null || value === undefined || value === '');

    isInitOdoAndEngineHoursDisabled = value => ((value !== '0.0'
        && this.validateInitOdoAndEngineHours(this.initialDataObjForRetrieval.trackingDeviceSerial)
        && ((this.validateInitOdoAndEngineHours(this.state.deviceSerialNumber)
            && this.initialDataObjForRetrieval.trackingDeviceSerial
                === this.state.deviceSerialNumber) || this.state.deviceSerialNumber === '')))

    render() {
        const { classes } = this.props;
        const {
            deleteDialogOpen, errorDialogOpen, successDialogOpen,
            errorTitle, errorMessage, deviceSerialNumber, ELDVisible, installationVisible,
            safetyVisible, fuelCardVisible, validationDialogBox, selectedAsset,
            editMode,
        } = this.state;
        this.trackingDistanceAndEngineHoursFieldsShowHide();
        const dOpen = deleteDialogOpen || errorDialogOpen || successDialogOpen;
        const dBtn1Text = deleteDialogOpen ? 'YES' : 'Ok';

        // eslint-disable-next-line no-nested-ternary
        const dTitle = deleteDialogOpen ? 'Confirm Asset Delete' : (errorDialogOpen ? (errorTitle || 'Error Occurred') : (errorTitle || 'Saved successfully'));
        // eslint-disable-next-line no-nested-ternary
        const dMessage = deleteDialogOpen ? 'Are you sure you want to delete this Asset?' : (errorDialogOpen ? (errorMessage || 'Please try again') : (errorMessage || 'Asset saved successfully'));
        // eslint-disable-next-line no-nested-ternary
        const dType = deleteDialogOpen ? 'delete' : (errorDialogOpen ? 'error' : 'success');

        return (
            <div className={classes.show}>
                {this.state.loading &&
                    <div style={c.inlined.loaderContainer} data-qa="loader_container">
                        <AppLoader loaderStyle={c.inlined.loaderStyle} />
                    </div>
                }
                <SaveErrorDialog
                    open={dOpen}
                    dialogTitle={dTitle}
                    dialogMessage={dMessage}
                    dialogBtn1Text={dBtn1Text}
                    dialogBtn1Click={() => (deleteDialogOpen ?
                        this.deleteHandler() : this.handleDialogClose(dType))}
                    dialogBtn2Text={deleteDialogOpen ? 'NO' : ''}
                    dialogBtn2Click={deleteDialogOpen ?
                        () => this.handleDialogClose(dType) : () => {}}
                    handleClose={() => this.handleDialogClose(dType)}
                />
                <Paper className={classes.header} data-qa="add_edit_asset_header_container">
                    <AddEditHeader
                        titleText={editMode ? 'Edit Asset' : 'Add Asset'}
                        tabClick={this.handleTabChange}
                        tabValue={this.state.tabValue}
                        cancelClick={() => this.handleDialogClose('success')}
                        saveClick={this.handleSaveClick}
                        deleteClick={() => this.handleDialogOpen('delete')}
                        editMode={editMode}
                        updateVisible={(!this.trackingDistanceFields.showInitialHubometer
                            && !this.trackingEngineHoursFields.showInitialEngineHours) || !(editMode && deviceSerialNumber !== '' && deviceSerialNumber)}
                        installationVisible={!installationVisible}
                        inputVisible={!deviceSerialNumber || deviceSerialNumber === '' || selectedAsset.deviceSerialNumber !== deviceSerialNumber}
                        fuelCardVisible={!fuelCardVisible}
                        ELDVisible={!ELDVisible}
                        safetyVisible={!safetyVisible}
                        maintenanceVisible={this.state.maintenanceVisible}
                    />
                </Paper>
                {this.renderAssetsCard(classes)}
                {(this.trackingDistanceFields.showInitialHubometer
                    || this.trackingEngineHoursFields.showInitialEngineHours) && editMode && deviceSerialNumber !== '' &&
                deviceSerialNumber && this.renderUpdatesCard(classes)}
                {this.renderAssociationsCard(classes)}
                {this.renderInstallationCard(classes)}
                {deviceSerialNumber && deviceSerialNumber !== '' && selectedAsset.deviceSerialNumber === deviceSerialNumber && this.renderInputsCard(classes)}
                {fuelCardVisible && this.renderFuelCard(classes)}
                {ELDVisible && this.renderELDCard(classes)}
                {safetyVisible && this.renderSafetyCard(classes)}
                {this.state.maintenanceVisible ? this.renderMaintenanceCard(classes) : null}
                <Dialog
                    open={validationDialogBox.isError}
                    type={this.getType()}
                    customTitle={validationDialogBox.header}
                    button={this.button}
                    content={validationDialogBox.msg}
                    size="lg"
                />
            </div>
        );
    }
}

const SafetyProfileDownMessage = () => (
    <div style={{ paddingTop: '5px' }}>
        <p>The safety profile is currently down. If you would like to set any buzzers on your asset,
            please send a request to customer support with your desired parameters.
        </p>
    </div>
);

export default withStyles(styles)(AddEditAsset);
